.checkbox {
  label {
    position: relative;
    display: flex;
    padding-left: 5px;
    cursor: pointer;
    overflow: hidden;

    &.checked {
      span {
        &:before {
          background-image: url("../assets/img/icons/icon-checked.svg");
        }
      }
    }
  }

  span {
    position: relative;
    padding-left: 35px;
    font-size: $font-size-base;
    line-height: 1.25;

    @include tablet {
      font-size: $font-size-h6;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid $grey-base;
      background-position: top right;
      background-repeat: no-repeat;
      background-size: 16px;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &.checkbox-sm {
    span {
      &::before {
        background-size: 10px 10px;
        background-position: 50% 40%;
      }
    }
  }
}

.checkbox-row {
  label {
    padding-left: 0;

    span {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: $font-size-small;
      font-weight: $font-medium;

      &:before {
        top: 2px;
      }
    }
  }
}
