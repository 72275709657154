.card-badge {
    position: absolute;
    background-color: #fff;
    font-size: 12px;
    padding: 3px 6px;
    margin: 5px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    text-decoration: none;
    color: inherit;
    user-select: none;
}