.camera-item {
  // display: flex;
  // padding: 110px 0;

  padding: 40px 0;

  @include tablet {
    padding: 80px 0;
  }

  @include laptop {
    display: flex;
    justify-content: space-between;
    padding: 110px 0;
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }
}

.camera-item__image {
  @include tablet-max {
    margin-bottom: 30px;
  }

  @include laptop {
    width: 550px;
    margin-bottom: 0;
  }

  @include desktop {
    width: auto;
  }

  img {
    display: block;
    width: 100%;

    @include tablet {
      height: 300px;
      object-fit: contain;
    }

    @include laptop {
      width: 660px;
      height: 400px;
    }
  }
}

.camera-item__info {
  @include laptop {
    width: 520px;
  }

  p {
    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: #585757;
  }
}

.camera-item__title {
  margin: 0;
  padding: 0 0 20px;
  font-size: $font-size-h4;
  line-height: 1.5;
  color: $grey-dark;
}

.camera-item__children {
  padding-bottom: 35px;
}

.camera-item__buttons {
  display: flex;

  @include mobile-max {
    flex-direction: column;
  }

  @include tablet-max {
    align-items: center;
    justify-content: space-around;
  }

  @include laptop {
    // flex-direction: row;
    justify-content: space-between;
  }

  a {
    display: block;
  }

  img {
    display: block;
    width: 100%;
  }

  .custom_button {
    width: 240px;
    min-width: 240px;
    height: 54px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile-max {
      margin-bottom: 20px;
    }
  }
}
