.edit-tour-header {
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 42px;
    height: 40px;
  }

  .page-back-link {
    margin-bottom: 0;

    a {
      width: auto;
    }
  }
}

.edit-tour-buttons {
  display: flex;

  &__mobile {
    @include mobile-max {
      display: block;
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid $grey-light;

      .btn-archive-tour,
      .btn-delete-tour {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

.btn-archive-tour,
.btn-delete-tour {
  @include button-reset-styles;

  width: 175px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(183, 192, 196, 0.19);
  border: 1px solid rgba(183, 192, 196, 0.19);

  border-radius: 2px;
  transition: border $t;

  img {
    margin-right: 15px;
  }

  span {
    font-size: $font-size-base;
    font-weight: $font-medium;
    text-transform: uppercase;
    color: $primary-color;
  }

  @include hover {
    border: 1px solid $primary-color;
  }
}

.btn-archive-tour {
  @include tablet {
    margin-right: 24px;
  }
}

.edit-tour-wrapper {
  background-color: $global-color;

  .tour-wrapper__title,
  .upload-images__wrapper,
  .tour-link-rooms {
    padding-top: 0;
  }

  .edit-tour__content {
    padding: 30px 15px;

    @include tablet {
      padding: 60px 40px 30px;
    }

    @include laptop {
      min-height: 50vh;
      padding: 90px 55px 50px;
    }

    .tour-wrapper__title {
      @include laptop {
        margin-top: -30px;
      }
    }
  }
}

.edit-tour-wrapper__title {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: $font-bold;
  color: $grey-dark;
  text-align: center;

  @include tablet {
    display: none;
  }
}
