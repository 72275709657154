.login-social-button {
  height: 62px !important;
  margin-bottom: 30px !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
  font-family: $font-family-base !important;
}

.login-social-google {
  background-color: $global-color;
  color: $grey-base !important;
  border: 1px solid $grey-base !important;
}

.login-social-apple {
  background-color: $grey-dark;
}
