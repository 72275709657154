.is-footer {
  background-color: $footer-bg;
  color: $global-color;

  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 70px;

  @include tablet {
    padding-top: 50px;
    // padding-left: 35px;
    // padding-right: 35px;
  }

  @include laptop {
    padding-top: 70px;
    padding-bottom: 0;
  }

  @include paddings;
}

.is-footer-container {
  @include tablet {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.footer-logo-wrapper {
  @include tablet {
    flex: 1 1 100%;
  }

  @include laptop {
    flex: 0;
  }
}

.footer-logo {
  display: block;

  padding-bottom: 65px;
  width: 215px;
  height: auto;
  filter: invert(1);

  @include tablet {
    margin: auto;
  }

  @include laptop {
    padding-bottom: 0;
  }
}

.footer-lists {
  display: flex;
  margin-bottom: 60px;

  @include mobile-max {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  @include tablet {
    margin-right: 5vw;
  }

  @include laptop {
    margin-right: 0;
  }
}

.footer-list {
  padding: 0;
  margin: 0;
  list-style-type: none;

  @include mobile-max {
    flex-basis: 50%;
    margin-bottom: 30px;
  }

  @include tablet-max {
    @include tablet {
      flex: 1 1 0;
    }
  }

  &:not(:last-child) {
    padding-right: 5vw;
  }

  li {
    font-size: $font-size-small;
    padding-top: 10px;
    padding-bottom: 10px;

    @include laptop {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: $font-size-h6;
    }

    &:first-child {
      font-weight: $font-medium;
      line-height: 1;
      padding-bottom: 10px;

      @include laptop {
        padding-bottom: 16px;
      }
    }
  }

  a {
    margin: 0;
    padding: 0;
    color: rgba($global-color, 0.8);
    text-decoration: none;
    @include underlined;

    @include mobile-max {
      font-size: $font-size-small;
    }
  }
}

.footer-contacts {
  padding-bottom: 50px;

  @include laptop {
    padding-bottom: 0;
  }

  img {
    width: 20px;
    margin-right: 16px;
  }

  li {
    display: flex;
    align-items: center;
    color: rgba($global-color, 0.8);
  }
}

.is-copyright {
  font-size: $font-size-xs;
  line-height: 1.6;
  color: rgba($global-color, 0.8);
  text-align: center;

  @include laptop {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: $font-size-h6;
    line-height: 1.6;
  }
}
