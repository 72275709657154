.is-container {
  padding: 0 20px;

  @include laptop {
    padding: 0 40px;
  }

  @include desktop {
    width: 1350px;
    padding: 0 20px;
    margin: 0 auto;
  }
}
