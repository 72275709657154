.tour-link-rooms {
  .tour-wrapper__footer {
    @include tablet-only {
      padding-top: 50px;
    }

    .checkbox {
      @include mobile-max {
        padding: 50px 0;
      }

      label {
        @include tablet-only {
          align-items: center;
          padding-left: 0;
        }

        span {
          @include tablet-only {
            font-size: 15px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.tour-link-rooms__mobile-title {
  font-weight: $font-medium;
  font-size: $font-size-h6;
  text-align: center;
  color: $grey-dark;
  padding: 20px 0 40px;

  @include tablet {
    display: none;
  }
}

.tour-link-rooms__body {
  @include tablet {
    display: flex;
    height: 500px;
  }
}

.tour-link-rooms__body-empty {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1.5;
  text-align: center;
}

.tour-link-rooms__add-pins {
  position: relative;
  background-color: $tour-link-dark;

  @include mobile-max {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    z-index: 112;
  }
}

#panorama {
  height: 100%;
  border-style:none;
  .pnlm-render-container {
    height: 100%;
  }
  .pnlm-about-msg {
    display: none;
  }
}

.tour-link-rooms__scene {
  position: relative;
  overflow: hidden;
  flex: 1;

  @include mobile-max {
    height: 450px;
  }

  .is-thumb-container {
    transition: filter $t, transform $t;

    &.is-hidden {
      transform: translateY(110px);

      @include tablet {
        transform: translateY(75px);
      }
    }

    &.is-not-active {
      pointer-events: none;
      opacity: 0.5;

      @supports (filter: blur(2px)) or (-webkit-filter: blur(2px)) {
        filter: blur(2px);
        opacity: 1;
      }
    }
  }

  .thumbnails-header {
    .tour-button {
      width: 40px;
      height: 25px;

      @include tablet {
        width: 35px;
        height: 18px;
      }

      @include hover {
        transform: none;
      }

      img {
        width: 14px;

        @include tablet {
          width: 12px;
        }
      }
    }
  }

  .thumbs {
    .thumb {
      @include thumb-size(130px, 80px);

      @include tablet {
        @include thumb-size(88px, 55px);
      }

      &:first-child {
        margin-left: 10px;
      }

      &:last-child {
        margin-right: 10px;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 11px;
        line-height: 15px;

        @include laptop {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}

.tour-link-rooms__rooms {
  position: relative;
  width: 260px;
  background-color: $tour-link-dark;

  // @include mobile-max {
  //   display: none;
  // }

  @include tablet {
    width: 170px;
  }

  @include laptop {
    width: 260px;
  }
}

.btn-default-pin {
  @include button-reset-styles;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 8px 10px 4px;

  font-size: $font-size-small;
  line-height: 1.5;

  color: $global-color;
  text-align: center;
  transition: $t;

  @include tablet {
    width: 100%;
    padding: 15px 10px 5px;
    font-size: 9px;
  }

  img {
    width: 30px;
    height: 30px;

    @include tablet {
      width: 32px;
      height: 32px;
    }
  }

  img + span {
    padding-top: 3px;

    @include tablet {
      paddinog-top: 15px;
    }
  }

  @include hover {
    background-color: rgba(43, 121, 146, 0.48);
  }
}

.btn-add-pin {
  &.is-active {
    background-color: rgba(43, 121, 146, 0.48);
  }
}

.btn-back-pin {
  min-width: 50px;

  img {
    width: 10px;
    // height: 14px;
  }
}

.btn-remove-pin {
  @include tablet {
    margin-top: 8px;

    img {
      width: 26px;
      height: 26px;
    }
  }
}

.btn-default-pin__wrapper {
  @include mobile-max {
    display: flex;
  }
}

@mixin tip {
  position: absolute;
  z-index: 5;

  background-color: $grey-transparent;
  border-radius: 2px;

  color: $global-color;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  width: 208px;
  height: 50px;
  font-size: $font-size-small;

  @include tablet {
    width: 208px;
    height: 39px;
    font-size: 11px;
  }

  @include laptop {
    width: 230px;
    height: 44px;
    font-size: $font-size-xs;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;

    @include tablet {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.add-pin__tip {
  @include tip;
  top: 80px;
  left: 58px;

  @include tablet {
    top: 15px;
    left: calc(100% + 25px);
  }

  &:before {
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent $grey-transparent transparent;

    @include mobile-max {
      bottom: 100%;
      left: 20px;
    }

    @include tablet {
      right: 100%;
      border-width: 7px 7px 7px 0;
      border-color: transparent $grey-transparent transparent transparent;
    }
  }
}

.add-room__tip {
  @include tip;
  top: 35px;
  right: calc(100% + 15px);

  @include laptop {
    top: 50px;
    right: calc(100% + 25px);
  }

  &:before {
    left: 100%;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent $grey-transparent;
  }

  &.add-room__tip-link-room {
    @include mobile-max {
      display: none;
    }
  }
}

// mobile hidden scene

.tour-link-rooms__body {
  @include mobile-max {
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(36, 36, 36, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }

    .tour-link-rooms__scene {
      opacity: 0.9;
    }

    .tour-link-rooms__add-pins,
    .add-pin__tip {
      display: none;
    }
  }
}

.expanded-link-window {
  .tour-link-rooms__scene {
    opacity: 1;
  }

  .tour-link-rooms__scene {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 111;
  }

  .tour-link-rooms__add-pins,
  .add-pin__tip {
    display: flex;
  }

  .btn-show-link-window,
  .tour-link-rooms__body::before {
    display: none;
  }
}

.btn-show-link-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;

  width: 180px;
  height: 42px;

  font-size: $font-size-base;
  font-weight: $font-medium;
  text-transform: none;

  @include tablet {
    display: none;
  }
}

.pnlm-hotspot {
  background-color: red;
}