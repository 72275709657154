.hero_banner {
  display: flex;

  padding-top: 20vh;

  min-height: 100vh;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  @include iphone-sm {
    min-height: calc(100vh - 70px);
  }

  @include iphone-md {
    min-height: calc(100vh - 75px);
  }

  @include iphone-lg {
    min-height: calc(100vh - 72px);
  }

  @include iphone-x {
    min-height: calc(100vh - 113px);
  }

  @include ipad {
    min-height: calc(100vh - 50px);
  }

  @include ipad-landscape {
    min-height: auto;
  }

  @include laptop {
    padding-top: 0;
    align-items: center;
    min-height: 850px;
    // background-size: 100% 100%;
    // background-size: 100%;
    // background-position: 50% 50%;
  }

  .custom_button {
    @include mobile-max {
      min-width: 252px;
      width: 100%;
    }
  }
}

.hero_banner__community-tours {
  padding-bottom: 20px;
}

.hero-camera {
  @include laptop {
    background-size: cover;
  }
}

.heading_h1 {
  font-size: $font-size-h3;
  font-weight: bold;
  line-height: 1;
  color: $grey-dark;
  margin: 0;
  padding: 0 0 30px;
  letter-spacing: 0.05em;

  @include tablet {
    padding: 0 0 50px;
  }

  @include laptop {
    font-size: $font-size-h1;
  }
}

.heading_h2 {
  font-size: $font-size-medium;
  font-weight: normal;
  line-height: 1.65;
  color: $grey-dark;
  margin: 0 0 40px;
  padding: 0;

  @include tablet {
    width: 50%;
    font-size: $font-size-h5;
  }

  @include laptop {
    font-size: 22px;
  }
}

.heading-border {
  text-align: center;
  padding-bottom: 45px;

  @include tablet {
    padding-bottom: 75px;
  }

  .header-description {
    font-size: 17px;
    text-align: center;
    width: 50%;
    margin-bottom: 55px;
    
    @include mobile-max {
      font-size: 15px;
      text-align: center;
      width: 100%;
      margin-bottom: 55px;
    }
  }

  h2 {
    margin: 0 auto;
    padding: 60px 0 30px;
    text-align: center;
    font-size: $font-size-h3;
    line-height: 1.4;
    color: $grey-dark;

    @include tablet {
      padding: 50px 0 35px;
      line-height: 1.5;
    }

    @include laptop {
      padding: 100px 0 45px;
      font-size: $font-size-h2;
      width: 50%;
    }

    &:after {
      content: "";
      margin: 30px auto auto;
      display: block;
      width: 128px;
      height: 1px;
      background-color: $grey-dark;

      @include tablet {
        margin: 45px auto auto;
      }
    }
  }

  p {
    margin: 0 auto;
    width: 80%;
    font-size: $font-size-base;
    line-height: 1.65;
    color: $grey-base;

    @include tablet {
      ont-size: $font-size-h5;
      width: 55%;
    }
  }
}

.is-block {
  @include tablet-max {
    overflow: hidden;
  }
}

.is-block-gradient {
  position: relative;
  padding-bottom: 50px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 90%;
    background: linear-gradient(160.75deg, #fefefe 16.14%, #d1d5d8 95.92%);
    z-index: -1;

    @include tablet {
      height: 75%;
    }
  }
}

.is-block-row {
  position: relative;
  margin-top: 20px;

  @include tablet-max {
    padding-bottom: 370px;

    .is-block-img img {
      transform: translate(0px, 330px);
      margin: auto;

      @include tablet {
        transform: translate(0px, 440px);
      }
    }
  }

  @include tablet {
    margin-top: 125px;
  }
}

$offset: 26px;
$width: 5%;

.is-block-row__reversed {
  @include laptop {
    margin-top: 300px;
  }

  .is-block-img {
    text-align: left;

    img {
      margin-left: 0;
    }
  }

  .is-block-info {
    left: auto;
    right: $offset;

    &:after {
      left: auto;
      right: -$width;
    }
  }
}

.is-block-img {
  position: relative;
  z-index: -2;

  @include mobile-max {
    height: 330px;
  }

  @include tablet {
    margin-bottom: 200px;
    z-index: -2;
  }

  img {
    display: block;

    @include mobile-max {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include tablet {
      width: 75%;
      height: auto;
      margin-left: auto;
    }
  }
}

.is-block-info {
  position: absolute;
  top: 0;
  left: $offset;
  padding: 25px;
  background-color: $global-color;

  @include tablet {
    top: -100px;
    padding: 50px 55px;
    width: 500px;
  }

  &:after {
    content: "";
    position: absolute;

    top: -$width;
    left: -$width;
    height: 100% + $width * 2;

    width: 100%;
    background-image: url(./../assets/img/landing/pattern-square.png);
    z-index: -1;
  }

  img {
    height: 35px;

    @include tablet {
      height: 50px;
    }
  }

  p {
    margin: 0;
  }
}

.is-block-info__title {
  padding-top: 20px;
  font-size: $font-size-base;
  font-weight: $font-bold;
  line-height: 1.4;
  color: $grey-dark;

  @include tablet {
    padding-top: 30px;
    font-size: $font-size-h4;
    line-height: 1.5;
  }
}

.is-block-info__text {
  padding-top: 20px;
  font-size: $font-size-small;
  line-height: 1.65;
  color: $grey-base;

  @include tablet {
    padding-top: 30px;
    font-size: $font-size-h5;
    line-height: 1.65;
  }
}

.subscriptions-wrapper {
  margin-bottom: 70px;

  @include tablet {
    margin-bottom: 90px;
  }
}

.subscription-item {
  padding: 25px;
  border-width: 1px;
  border-style: solid;
  border-top-width: 13px;
  border-color: #1A4458;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;

  .main-section {
    width: 100%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 48px;
    p {
      margin: 0;
    }
    div {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  .subscribe-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 262px;
    gap: 16px;
  }

  @include mobile-max {
    margin-bottom: 70px;
    flex-direction: column;

    .main-section {
      max-width: 100%;
    }
  }
  

  @include tablet-only {
    margin-left: auto;
    margin-right: auto;
  }

  @include tablet {
    padding: 50px;
  }

  .custom_button {
    min-width: 100%;
  }
}

.subscription-type {
  margin: 0;
  font-size: $font-size-h4;
  font-weight: $font-semibold;
  text-transform: capitalize;
  text-align: left;
  color: black;

  @include tablet {
    font-size: $font-size-h3;
  }
}

.subscription-price {
  margin: 0;
  font-size: $font-size-h4;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.02em;
  color: black;

  span {
    font-size: 54px;
  }

  @include tablet {
    font-size: $font-size-h3;
  }
}

.subscription-options {
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1;
  padding-top: 8px;

  li {
    position: relative;
    display: flex;
    margin-left: 30px;
    padding-bottom: 24px;
    font-size: $font-size-small;
    line-height: 1;
    color: $grey-dark;

    &:before {
      content: "";
      position: absolute;
      top: 3px;
      left: -18px;
      width: 15px;
      height: 10px;
      background-image: url(./../assets/img/icons/icon-checked-long.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @include tablet {
        left: -30px;
        width: 20px;
        height: 12px;
      }
    }

    @include tablet {
      margin-left: 30px;
      padding-bottom: 24px;
      font-size: $font-size-medium;
    }

    &:first-child, &:nth-child(2) {
      font-weight: $font-bold;
    }
  }

  &.how-it-works {
    li {
      margin: 0 auto;
      font-weight: normal;
      text-align: left;

      @include mobile-max {
        width: 80%;
      }
    }
  }
}

.how-vs-works {
    display: flex;
    justify-content: space-around;
    text-align: center;
    list-style: none;

    @include mobile-max{
      flex-direction: column;
    }

    .stepCount {
      align-self: flex-start;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      font-size: 18px;
      margin-top: 10px;
    }

    div {
      display: flex;
      margin-bottom: 30px;
    }

    .stepContainer {
      display: flex;
      margin: 0 15px;
      border-left: 1px solid #686868;
      padding-left: 20px;
      font-size: 32px;
      font-weight: 700;
      color: black;
      height: 390px;
        li {
          align-self: flex-end;
          text-align: left;
        }
        span {
          color: #686868;
          font-size: 65px;
          font-weight: 400;
          position: absolute;
        }
  }
}

.is-pricing {
  padding-bottom: 50px;

  @include tablet {
    padding-bottom: 100px;
  }
}

.enterprise-banner {
  position: relative;

  width: calc(100% - #{$offset});
  margin-left: auto;
  padding: 30px 25px;
  border: 1px solid $grey-light;
  background-color: $global-color;

  @include laptop {
    display: flex;
    align-items: flex-end;
    width: calc(100% - #{$offset});
    margin-left: auto;
    padding: 70px 90px 60px;
  }

  &:after {
    content: "";
    position: absolute;
    top: $offset;
    left: -$offset;
    width: 100%;
    height: 100%;
    background-image: url(./../assets/img/landing/pattern-long.png);
    z-index: -1;
  }

  .custom_button {
    @include mobile-max {
      width: 100%;
    }
  }
}

.enterprise-banner__title {
  margin: 0;
  padding-bottom: 20px;
  font-size: $font-size-medium;
  font-weight: $font-bold;
  color: $primary-color;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  @include tablet {
    font-size: $font-size-h3;
  }

  @include laptop {
    font-size: $font-size-h2;
  }
}

.enterprise-banner__text {
  font-size: $font-size-small;
  line-height: 1.65;

  @include tablet-max {
    margin-bottom: 20px;
  }

  @include tablet {
    max-width: 80%;
    font-size: $font-size-h5;
  }

  @include laptop {
    font-size: $font-size-h4;
  }
}

.appStoreBanner {
  position: relative;
  padding-top: 60px;
  padding-bottom: 80vh;
  padding-bottom: 500px;
  background-color: $grey-light-bg;

  @include tablet-max {
    overflow: hidden;
  }

  @include tablet {
    padding-bottom: 75vh;
  }

  @include laptop {
    position: relative;
    padding-top: 180px;
    padding-bottom: 300px;
  }

  h2 {
    margin: 0;
    padding: 0 0 30px;
    font-size: $font-size-h3;
    font-weight: $font-bold;
    line-height: 1.45;
    color: $grey-dark;

    @include tablet-max {
      text-align: center;

      &:after {
        content: "";
        display: block;
        margin: 30px auto 0;
        width: 90px;
        height: 1px;
        background-color: $grey-dark;
      }
    }

    @include laptop {
      padding: 0 0 40px;
      font-size: $font-size-h2;
    }
  }

  p {
    margin: 0;
    padding: 0 0 40px;
    font-size: $font-size-base;
    line-height: 1.65;

    @include tablet-max {
      text-align: center;
      max-width: 90%;
      margin: 0 auto;
    }

    @include laptop {
      max-width: 45%;
      padding: 0 0 40px;
      font-size: $font-size-h4;
    }
  }
}

.appStoreButton {
  position: relative;
  display: block;
  width: 100%;
  transition: opacity $t;

  @include mobile-max {
    margin: 0 auto;
    height: 80px;
  }

  @include hover {
    opacity: 0.7;
  }

  @include tablet {
    width: 250px;
    height: 70px;
    margin-right: auto;
  }

  @include laptop {
    width: 320px;
    height: 90px;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.phone-image {
  position: absolute;

  @include tablet-max {
    right: auto;
    left: 12vw;
    bottom: 20px;

    display: block;
    max-width: 100%;
    height: auto;
  }

  @include tablet {
    right: 0;
    left: auto;
  }

  @include laptop {
    top: 160px;
    right: 0;
  }
}

.scrolltopLink {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: $font-size-medium;
  color: $grey-base;

  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
  transition: $t;

  &.desktop-visible {
    @include laptop {
      right: calc((100vw - 1350px) / 2 - 100px);
    }
  }

  &.is-active {
    opacity: 1;
    pointer-events: unset;
    transform: translateY(0);
  }

  img {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  @include laptop {
    &:not(.desktop-visible) {
      display: none;
    }
  }

  &.onlyIcon {
    img {
      margin: 0;
      width: 42px;
      height: 42px;
    }
  }
}
