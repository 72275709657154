.tours-wrapper {
  display: flex;
}

.tours-tabs {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  @include tablet {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    margin: 35px 0;
  }
}

.tours-tab-button {
  appearance: none;
  margin: 0;
  padding: 0;

  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-medium;
  line-height: 1.25;

  background-color: transparent;
  color: $grey-base;
  transition: color $t;

  .tours-word {
    display: none;
  }

  @include mobile-max {
    span {
      display: none;
    }
  }

  @include tablet {
    font-size: $font-size-medium;
  }

  &.is-active {
    color: $primary-color;

    .tours-word {
      display: inline-block;
    }
  }

  @include hover {
    cursor: pointer;

    color: $primary-color;
  }
}

.tours-filter-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;

  @include tablet {
    padding-bottom: 40px;
  }
}

.tours-sort-panel {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
}

.btn-tours-filter {
  @include tablet {
    display: none;
  }
}
