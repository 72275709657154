.is-alert {
  display: flex;
  justify-content: space-between;
  padding: 13px 24px;
  margin-bottom: 40px;
  background: rgba(248, 62, 95, 0.19);
  border-radius: 2px;
}

.is-alert__content {
  display: flex;
}

.is-alert__icon {
  margin-right: 20px;
}

.is-alert__text {
  font-size: $font-size-small;
  line-height: 1.5;
  color: $warning-color;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @include laptop {
    width: 75%;
  }
}

.is-alert__close {
  appearance: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
}

.alert-tour-wrapper {
  display: none !important;

  &.is-block {
    display: block;
  }
}
