.search-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 20px;
  background-color: $global-color;
  border-radius: 2px;

  @include tablet {
    padding: 8px 8px 8px 20px;
  }
}

.search-field {
  position: relative;

  @include tablet-max {
    width: 100%;
  }

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  input {
    box-shadow: none;
    outline: none;

    display: block;
    border: 0;
    background-color: $global-color;

    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 35px;

    font-size: $font-size-base;
    color: $grey-base;

    @include mobile-max {
      height: 40px;
    }

    @include laptop {
      min-width: 400px;
    }

    @include placeholder {
      color: $grey-light;
    }
  }
}

.tours-pagination {
  display: flex;
  justify-content: center;
  padding-top: 40px;

  @include tablet {
    padding-top: 50px;
    justify-content: flex-end;
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
  }

  li:not([disabled]) {
    .tours-pagination__link {
      @include hover {
        color: $primary-color;

        img {
          transform: scale(1.2);
        }
      }
    }
  }
}

.tours-pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  padding: 7px 5px 5px;

  background-color: $global-color;
  border: 1px solid $grey-light-border;
  border-right-width: 0;

  font-size: $font-size-small;
  font-weight: $font-medium;
  line-height: 1;
  color: rgba(50, 60, 71, 0.4);

  &,
  img {
    transition: $t;
  }
}

.tours-pagination {
  .disabled {
    cursor: not-allowed;
  }
}

.tours-pagination__active {
  font-weight: $font-bold;
  color: $primary-color;
}

.tours-pagination__first {
  .tours-pagination__link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.tours-pagination__last {
  .tours-pagination__link {
    border-right-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.btn-search-clear {
  @include button-reset-styles;

  padding: 14px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  img {
    position: static;
    display: block;
    width: 12px;
    height: 12px;
    transform: none;
  }
}
