.select-field {
  display: flex;
  align-items: center;

  .react-select-container {
    width: 170px;

    @include mobile-max {
      width: 38px;
    }
  }

  .react-select {
    &__control {
      cursor: pointer;
      border-color: transparent;
      border-radius: 2px;
      box-shadow: none !important;
      transition: border-color $t;

      &:hover {
        border-color: $light-primary;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      margin: 0;

      @include mobile-max {
        display: none;
      }
    }

    &__value-container {
      padding-left: 13px;

      @include mobile-max {
        display: none;
      }
    }

    &__dropdown-indicator {
      @include mobile-max {
        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("./../assets/img/icons/icon-arrows.svg");
          background-position: center;
        }
      }

      @include tablet {
        padding-right: 13px;
        align-items: center;
        justify-content: center;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 5px 0 5px;
          border-color: #73868f transparent transparent transparent;
        }
      }

      svg {
        display: none;
      }
    }

    &__menu {
      @include mobile-max {
        width: 170px;
        left: auto;
        right: 0;
        z-index: 3;
      }
    }

    &__option {
      cursor: pointer;

      &--is-active {
        background-color: $primary-color;
      }

      &--is-selected,
      &--is-focused {
        background-color: $light-primary;
        color: $global-color;
      }
    }
  }
}

.select-label {
  display: none;

  @include tablet {
    padding-right: 15px;
    font-size: $font-size-small;
    color: $grey-dark;
    font-weight: $font-medium;
  }
}
