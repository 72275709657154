.page-wrapper {
  min-height: 100vh;
  background-color: $grey-bg;

  padding-top: 60px + 20px;
  padding-bottom: 80px;

  @include tablet {
    padding-top: 84px + 45px;
  }
}

.page-back-link {
  margin-bottom: 20px;

  @include tablet {
    margin-bottom: 42px;
  }

  a {
    display: flex;
    flex-basis: 10%;
    align-items: center;
    font-size: $font-size-h6;
    font-weight: $font-medium;
    color: $primary-color;
    transition: all $t;

    @include hover {
      color: $light-primary;
    }

    @include laptop {
      width: 50%;
    }

    img {
      height: 10px;
      margin-right: 10px;
    }

    span {
      margin-top: 2px;
      line-height: 1;
    }
  }
}
