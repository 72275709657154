.acw-wrapper {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: $grey-light-bg;
}

.acw-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  appearance: none;
  background-color: $global-color;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: $font-family-base;

  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
  background-color: $global-color;
  box-shadow: 0px 1px 2px rgba(23, 23, 23, 0.1);
  z-index: 102;

  img {
    height: 14px;
  }

  span {
    font-family: $font-family-base;
    font-size: $font-size-medium;
    font-weight: $font-medium;
    color: $grey-dark;
    text-transform: uppercase;
  }
}

.acw-body {
  margin: 20px 20px 0;
  background-color: $global-color;

  .invoices-table {
    max-height: none;
    opacity: 1;
  }
}

.acw-content {
  padding: 20px;
  min-height: 50vh;
  text-align: center;

  @include mobile-max {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0 0 20px;
    font-size: $font-size-base;
    line-height: 1.5;
    color: $grey-dark;
  }
}
