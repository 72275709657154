.Toastify__toast {
  @include mobile-max {
    min-height: 40px;
    width: 78%;
    margin-left: 20%;
    top: 0;
  }
}

.Toastify__toast--success {
  background-color: $light-primary !important;
}

.Toastify__toast--error {
  background-color: $warning-color !important;
}

.Toastify__toast-body {
  font-family: $font-family-base;
  color: $global-color;
  font-size: $font-size-base;
  padding: 5px 10px;

  @include tablet {
    padding: 10px;
  }
}

.Toastify__close-button {
  opacity: 1 !important;

  svg {
    fill: $global-color !important;
  }
}

.Toastify__toast-container {
  top: 8px;

  @include tablet {
    top: 4px;
    right: 5px;
  }

  @include laptop {
    right: 30px;
  }
}
