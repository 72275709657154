.tours-container {
  display: grid;
  grid-gap: 14px;
  column-gap: 14px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include tablet {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include laptop {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &:empty {
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet {
      min-height: 20vh;
    }

    &:after {
      content: "Sorry, we do not have any results. Please try again.";
      font-size: $font-size-base;
      font-weight: $font-medium;
      line-height: 1.5;
      padding: 20px;
      color: $grey-base;
      text-align: center;
    }
  }
}

.list-tours-view {
  grid-template-columns: repeat(1, minmax(0, 1fr));

  .tour-item {
    display: flex;

    @include mobile-max {
      padding-bottom: 8px;
    }
  }

  .tour-image-container {
    position: static;
    margin-right: 7px;
    margin-bottom: 0;

    @include tablet {
      margin-right: 20px;
    }
  }

  .tour-image {
    width: 220px;
    height: 160px;

    @include mobile-max {
      width: 125px;
      height: 85px;
      min-height: 85px;
    }
  }

  .tour-views {
    @include mobile-max {
      display: none;
    }
  }

  .tour-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .tour-characteristics-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile-max {
      flex: 1 1;
    }
  }

  .tour-condition__row {
    font-size: $font-size-small;

    span {
      @include tablet {
        display: inline-block;
      }
    }

    @include mobile-max {
      font-size: $font-size-xs;
    }

    img {
      margin-right: 10px;
      margin-top: -2px;

      @include mobile-max {
        margin-right: 5px;
        margin-top: -1px;
      }
    }
  }

  .tour-name {
    margin-bottom: 10px;
    line-height: 1.25;

    @include tablet-max {
      max-width: 40vw;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
    }

    @include mobile-max {
      max-width: 35vw;
      font-size: $font-size-xs;
    }

    @include tablet {
      margin-bottom: 20px;
    }
  }

  .tour-price {
    top: 10px;
    min-width: 130px;
    padding: 8px 4px 7px;

    @include mobile-max {
      right: 0;
      min-width: 50px;
      font-size: $font-size-xs;
    }
  }

  .tour-views {
    color: $grey-dark;
    background-color: transparent;
    bottom: 15px;
    right: 65px;
    justify-content: flex-end;
    pointer-events: none;
    font-size: $font-size-small;

    svg {
      fill: $grey-dark;
    }
  }

  .btn-tour-share {
    img {
      margin: 0;
      width: 12px;
      height: 12px;

      @include tablet {
        width: 20px;
        height: 20px;
      }
    }
  }

  .tour-edit__icon {
    position: absolute;

    top: 13px;
    left: 13px;

    @include tablet {
      top: 22px;
      left: 22px;
    }
  }

  .appartments-conditions {
    display: flex;
    margin-bottom: 12px;
  }

  .tour-item__archived {
    .tour-views {
      left: auto;

      @include tablet {
        right: 140px;
      }

      @include laptop {
        right: 120px;
      }
    }
  }
}

.grid-tours-view {
  .tour-item {
    @include mobile-max {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .tour-info {
    @include mobile-max {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .tour-image-container {
    @include mobile-max {
      margin-bottom: 7px;
    }

    @include tablet-only {
      min-height: 50px;
    }
  }

  .tour-characteristics-container {
    @include tablet {
      display: flex;
      flex-direction: column;
      min-height: 56px;
      justify-content: space-between;
    }
  }

  .tour-item {
    @include mobile-max {
      padding-bottom: 8px;
    }
  }

  .tour-price {
    @include tablet {
      right: -18px;
    }
  }

  .tour-name {
    @include laptop {
      min-height: 36px;
    }
  }

  .tour-edit__icon {
    position: absolute;

    top: 4px;
    left: 4px;

    @include tablet {
      top: 7px;
      left: 7px;
    }
  }
}

.tour-item {
  position: relative;
  background-color: $global-color;
  padding: 10px;
  transition: box-shadow $t;

  @include tablet {
    padding: 16px;
  }

  @include hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0px rgba($light-primary, 0.2);
  }
}

.tour-item__archived {
  .tour-condition__row {
    @include iphone-sm {
      display: block;
    }
  }

  .btn-tour-dearchive {
    @include iphone-sm {
      margin-top: 10px;
    }
  }
}

.tour-item__own {
  border: 2px solid #44ddff;
}

.tour-item__disabled {
  pointer-events: none;
}

.tour-image-container {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #877f7f;
  border-radius: 4px;

  @include tablet {
    margin-bottom: 15px;
  }
}

.tour-additional__images {
  display: flex;
  margin-bottom: 10px;

  @include mobile-max {
    display: none;
  }

  &:empty {
    display: none;
  }

  .tour-image {
    width: 55px;
    height: 55px;
    min-height: 55px;
    border: none;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.tour-image {
  display: block;
  width: 100%;
  height: 15vh;
  min-height: 95px;
  object-fit: cover;
  border: 2px solid $global-color;
  border-radius: 4px;
  transition: object-fit $t;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @include tablet {
    height: 150px;
  }
}

.tour-image-container__empty {
  border: none;
}

.tour-image__empty {
  border: none;
}

.tour-price {
  position: absolute;
  right: 0px;
  top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px 0 5px;
  min-width: 88px;

  font-size: $font-size-small;
  font-weight: $font-medium;

  color: $global-color;
  background-color: $light-primary;
  border-radius: 2px 0 0 2px;

  @include mobile-max {
    min-width: 62px;
    font-size: $font-size-xs;
    right: -11px;
  }
}

.tour-views {
  position: absolute;
  left: 4px;
  bottom: 10px;
  padding: 5px 9px;
  min-width: 77px;
  display: flex;
  align-items: center;
  justify-content: center;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $global-color;
  background-color: rgba(45, 57, 61, 0.4);
  border-radius: 2px;

  font-size: $font-size-small;
  line-height: 1;

  @include mobile-max {
    left: 10px;
    min-width: 66px;
    padding: 2px 5px;
    font-size: $font-size-xxs;
  }

  svg {
    margin-right: 4px;
    fill: $global-color;
  }
}

.tour-name {
  margin-bottom: 8px;
  font-size: $font-size-xs;
  font-weight: $font-semibold;
  line-height: 1.5;
  color: $grey-dark;

  @include tablet {
    font-weight: $font-bold;
  }
}

.tour-condition__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  line-height: 1;
  color: $grey-dark;

  @include mobile-max {
    min-height: 17px;
  }

  img {
    margin-right: 5px;

    @include mobile-max {
      width: 1.65vh;
      height: 1.65vh;
    }

    // &.tour-condition__icon-bedroom {}

    &.tour-condition__icon-bath {
      margin-top: -2px;

      @include tablet {
        width: 16px;
        height: 16px;
      }
    }

    &.tour-condition__icon-area {
      @include mobile-max {
        width: 1.6vh;
        height: 1.6vh;
        margin-right: 4px;
      }
    }
  }

  span {
    @include tablet-max {
      display: none;
    }
  }
}

.appartments-conditions {
  display: flex;
  min-height: 16px;
  margin-bottom: 10px;

  &:empty {
    min-height: 0;
    margin-bottom: 0;
  }

  @include tablet {
    min-height: 16px;
  }

  .tour-condition__row {
    margin-right: 10px;
    justify-content: flex-start;

    &:last-child {
      margin-right: 0;
    }
  }
}

.tour-image-container {
  &.is-loading {
    background-color: $grey-light;
    border: none;

    .tour-image {
      margin: 0;
      border: none;
    }
  }
}

.tour-condition__row,
.appartments-conditions,
.tour-name {
  &.is-loading {
    filter: blur(3px);
  }
}

.btn-tour-share {
  @include button-reset-styles();
  transform: translateY(2px);

  @include hover {
    .tour-share__icon {
      transform: scale(1.1);
    }
  }

  .tour-share__icon {
    width: 14px;
    height: 14px;
    margin: 0;
    will-change: transform;
    transition: transform $t;

    @include laptop {
      width: 20px;
      height: 20px;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.btn-tour-dearchive {
  @include button-reset-styles;
  padding: 5px 10px 3px;
  border-radius: 2px;
  background-color: $light-primary;
  color: $global-color;
  font-weight: $font-semibold;
  transition: background-color $t;

  @include mobile-max {
    width: 80px;
  }

  @include iphone-sm {
    width: 100%;
  }

  @include hover {
    background-color: $primary-color;
  }
}
