.credit-card {
  .credit-card__row {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }
  }
  .is-input-error {
    border-color: #f83e3e;
  }
  .credit-card-error-wrapper {
    border-radius: 4px;
    background: rgba(248, 62, 62, 0.15);
    display: none;

    &.is-active {
      display: block;
    }

    .credit-card-error {
      display: flex;
      align-items: center;
      font-size: $font-size-base;
      line-height: 1.25;
      letter-spacing: -0.1px;
      margin-bottom: 20px;
      padding: 10px 5px 10px 10px;

      img {
        position: relative;
        margin-left: 10px;
        left: auto;
        top: -2px;
        transform: translateY(0);
      }

      p {
        margin: 0 10px;
        color: $warning-color;
        font-size: $font-size-small;
        @include short-laptop {
          font-size: 13px;
        }
      }
    }
  }

  .text-field {
    position: relative;
    margin-bottom: 18px;
  }

  .separator {
    width: 110px;
  }

  button {
    display: block;
    margin: auto;
  }

  input {
    padding-left: 44px;
    color: #73868f;
    border: 1.12915px solid #b7c0c4;
  }

  img {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  p {
    text-align: center;
    margin-top: 0;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;

    text-align: center;
    color: #686868;
    font-weight: normal;
  }
}
