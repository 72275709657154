.charts-wrapper {
  padding-bottom: 20px;

  @include tablet {
    padding-bottom: 40px;
  }

  @include laptop {
    display: flex;
    justify-content: space-around;
  }
}

.chart-wrapper-graph {
  .recharts-wrapper > svg {
    transform: translateX(-25px);

    @include tablet {
      transform: translateX(-20px);
    }

    @include laptop {
      transform: none;
    }
  }
}

.chart-rad-legend {
  display: flex;
  padding-top: 40px;
  justify-content: space-around;

  @include tablet {
    justify-content: center;
  }
}

.chart-rad-legend__item {
  display: flex;
  align-items: center;

  @include tablet {
    padding-right: 40px;
  }
}

.chart-rad-legend__icon {
  display: block;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 2px;
  margin-right: 10px;
  color: $grey-base;
}

.recharts-wrapper {
  @include tablet-max {
    margin-left: auto;
    margin-right: auto;
  }
}

.unique-icon {
  background-color: $light-primary;
}

.unique-text {
  color: $light-primary;
}

.total-icon {
  background-color: $primary-color;
}

.total-text {
  color: $primary-color;
}

.chart-rad-wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.chart-rad {
  margin-bottom: 40px;

  @include tablet {
    margin-bottom: 60px;
  }
}

.chart-rad-views {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  span {
    // display: flex;
    font-size: $font-size-h4;

    span {
      font-size: $font-size-h1;
      font-weight: $font-bold;
    }
  }
}

.empty-analytics {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile-max {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0;
    line-height: 1.5;
    text-align: center;

    @include mobile-max {
      padding: 20px;
    }

    @include laptop {
      padding-top: 80px;
    }
  }
}
