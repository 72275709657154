.settings__wrapper {
  // display: none;

  @include tablet {
    display: flex;
  }
}

.settings-right__col {
  @include tablet {
    min-width: 200px;
    max-width: 200px;
    margin-right: $account-gap;
  }

  @include laptop {
    min-width: 330px;
    max-width: 330px;
  }
}

.settings-left__col {
  @include tablet {
    flex: 0 1 100%;
  }
}

.settings__images {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: $account-gap;
  padding: 20px;

  background-color: $global-color;
  border-radius: 2px;

  @include laptop {
    padding: 30px 30px 35px;
  }
}

.account-photo__wrapper {
  position: relative;
}

.account-photo-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.account-photo {
  margin-bottom: 30px;
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid $light-primary;
  border-radius: 50%;

  @include tablet {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  @include laptop {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }
}

.account-photo__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 4px solid $global-color;
  border-radius: 50%;
}

.account-photo__camera {
  position: absolute;
  bottom: 0;
  right: 0;

  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $light-primary;
  border-radius: 50%;

  @include tablet {
    width: 32px;
    height: 32px;
  }

  @include laptop {
    width: 36px;
    height: 36px;
  }

  img {
    display: block;
    object-fit: contain;
    width: 50% !important;
  }
}

.account-photo__placeholder {
  border-color: $grey-light;

  img {
    width: 80%;
    margin: auto;
    object-fit: contain;
  }
}

.settings__name {
  font-size: $font-size-medium;
  font-weight: $font-medium;
  color: $grey-dark;
  padding: 0 0 15px;
  margin: 0;

  @include tablet {
    font-size: $font-size-base;
    padding: 0 0 15px;
  }

  @include laptop {
    font-size: $font-size-medium;
    padding: 0 0 23px;
  }
}

.settings_info__row {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.settings__project {
  p {
    margin: 0;
    font-size: $font-size-medium;
    font-weight: $font-medium;
    color: $grey-dark;
    text-align: center;

    @include tablet {
      font-size: $font-size-base;
    }

    @include laptop {
      font-size: $font-size-medium;
    }

    span {
      display: block;
      padding-top: 5px;
      font-size: $font-size-base;
      font-weight: 400;
      color: $grey-base;
    }
  }
}

.settings-tabs-header,
.edit-tabs-header,
.share-tour-tabs-header {
  display: flex;
  justify-content: space-arou nd;
  padding: 0 $tab-settings-laptop-gap;
  border-bottom: 1px solid $grey-light;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: $global-color;

  @include tablet {
    padding: 0 $tab-settings-tablet-gap;
    justify-content: space-between;
  }

  @include laptop {
    padding: 0 $tab-settings-laptop-gap;
    justify-content: space-around;
  }
}

.settings-tabs-header {
  @include mobile-max {
    display: none;
  }
}

.edit-tabs-header {
  padding: 0;
  justify-content: space-around;

  @include laptop {
    justify-content: center;
  }

  .settings-tabs-button {
    @include mobile-max {
      span {
        display: none;
      }
    }

    @include laptop {
      margin-left: 90px;
      margin-right: 90px;
    }
  }
}

.share-tour-tabs-header {
  border-bottom: 0;
  background-color: $grey-bg;
  justify-content: space-around;

  @include tablet {
    justify-content: space-evenly;
  }

  @include laptop {
    justify-content: space-around;
  }
}

.settings-tabs-button {
  position: relative;
  appearance: none;
  margin: 0;
  padding: 28px 0;
  font-family: $font-family-base;
  font-size: $font-size-small;
  font-weight: $font-medium;
  color: $grey-base;
  background-color: $global-color;
  text-transform: uppercase;
  cursor: pointer;

  @include tablet {
    font-size: $font-size-base;
  }

  &:after {
    content: "";

    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 0px;
    height: 3px;
    background-color: $primary-color;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition: width $t;
  }

  @include hover {
    color: $primary-color;
  }

  &.is-active {
    color: $primary-color;

    &:after {
      width: 50px;
    }
  }
}

.settings-tabs-wrapper {
  background-color: $global-color;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.settings-tab {
  @include tablet {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

.settings-form__wrapper {
  &:last-child {
    .settings-form {
      border-bottom: none;
    }
  }
}

.settings-form {
  padding: 25px 20px;

  @include tablet {
    padding: 0 $tab-settings-laptop-gap $tab-settings-laptop-gap $tab-settings-laptop-gap;
    border-bottom: 1px solid $grey-light;
  }

  @include laptop {
    padding: 0 160px $tab-settings-laptop-gap $tab-settings-laptop-gap;
  }

  .custom_button__light {
    width: 100%;

    @include tablet {
      width: 160px;
      max-width: 160px;
      min-width: 160px;
    }

    @include desktop {
      width: 180px;
      max-width: 180px;
      min-width: 180px;
    }
  }

  .text-field {
    label {
      min-width: 185px;
    }
  }

  p,
  ul {
    font-size: $font-size-base;
    line-height: 2;
    margin: 0;
    padding: 0;

    @include tablet {
      font-size: $font-size-small;
    }

    @include laptop {
      font-size: $font-size-base;
    }
  }

  ul {
    padding-left: 18px;
    margin-top: 10px;
    margin-bottom: 30px;

    @include tablet {
      margin-bottom: 50px;
    }
  }
}

.settings-form__title {
  display: flex;
  margin-bottom: 50px;
  padding-top: $tab-settings-laptop-gap;
  padding-left: $tab-settings-laptop-gap;
  font-size: $font-size-medium;
  font-weight: $font-medium;
  color: $grey-dark;

  @include mobile-max {
    display: none;
  }

  @include tablet {
    margin-bottom: 40px;
    padding-top: $tab-settings-laptop-gap;
    padding-left: $tab-settings-tablet-gap;
    font-size: $font-size-base;
  }

  @include laptop {
    margin-bottom: 50px;
    padding-top: $tab-settings-laptop-gap;
    padding-left: $tab-settings-laptop-gap;
    font-size: $font-size-medium;
  }

  img {
    margin-right: 10px;
    display: block;
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
}

.subscription-date-info {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(23, 23, 23, 0.4);
}

.settings-form__subtitle {
  @include mobile-max {
    width: 240px;
    margin: 0 auto;
    padding-top: 25px;
    text-align: center;
    font-size: $font-size-small;
    line-height: 1.5;
  }

  @include tablet {
    margin-top: -20px;
    padding-left: $tab-settings-laptop-gap;
    padding-bottom: $tab-settings-laptop-gap;
  }
}

.settings-form__footer {
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .custom_button__light {
    @include mobile-max {
      margin-bottom: 20px;
    }
  }

  .error,
  .success {
    @include mobile-max {
      text-align: center;
    }
  }
}

.settings-subscription__row {
  @include mobile-max {
    padding: 20px 0;

    display: flex;
    justify-content: center;
  }

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $tab-settings-laptop-gap - 7 $tab-settings-tablet-gap;
    border-bottom: 1px solid $grey-light;
  }

  @include laptop {
    padding: $tab-settings-laptop-gap - 7 $tab-settings-laptop-gap;
  }

  .settings-form__title {
    padding: 0;
    margin: 0;
  }

  .custom_button__light {
    @include mobile-max {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .settings-form__title {
    @include mobile-max {
      padding-bottom: 30px;
    }
  }
}

.settings-subscription__footer {
  @include mobile-max {
    padding: 20px;

    .custom_button__light {
      width: 100%;
    }
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: 300px;
    padding: 0 $tab-settings-tablet-gap + 2px $tab-settings-tablet-gap;
  }

  @include laptop {
    padding: 0 $tab-settings-laptop-gap $tab-settings-laptop-gap;
  }

  .custom_button__light {
    min-width: 220px;
  }

  .error {
    padding-top: 30px;

    @include tablet {
      font-size: $font-size-small;
    }
  }
}
