.range-filter {
  position: relative;
  flex: 1;

  .range-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-left: 20px;
    background-color: $global-color;
    border-left: 1px solid $grey-base;
    transition: border-color $t;

    &.is-visible {
      border-left-color: $primary-color;
    }
  }
  .title {
    font-size: $font-size-xs;
    font-weight: $font-bold;
    line-height: 1.5;

    letter-spacing: 0.5;
    text-transform: uppercase;
  }

  .range-value-wrapper {
    position: relative;
  }

  .placeholder {
    font-size: $font-size-small;
    line-height: 1.25;
    color: $grey-light;
  }

  .clear-button {
    margin: 0;
    padding: 0;
    appearance: none;

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: $global-color;

    cursor: pointer;

    img {
      width: 10px;
      height: 10px;
    }
  }

  .count-popup {
    position: absolute;
    background-color: white;
    z-index: 10 !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 37px !important;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-around;
    width: 100%;
    border-radius: 3px;
    padding: 20px 20px 0;
    left: 0;
    top: 55px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
  }
  .counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  .increase,
  .decrease {
    border-radius: 50%;
    border: 0;
    margin: 0 5px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    background-color: white;
    cursor: pointer;
    span {
      width: 24px;
      height: 24px;
    }
  }
  .apply-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .apply-button {
    margin: 15px 0;
    padding: 10px;
    background-color: #2c7992;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    border: 0px;
    width: 90px;
    height: 35px;
  }
}

.counter-icon {
  display: none;
}

.range-filter.large-icons {
  .counter {
    width: 110px;

    svg {
      display: none;
    }
  }

  .counter-icon {
    display: block;
  }

  .current-value {
    padding-top: 2px;
  }

  .increase,
  .decrease {
    &,
    span {
      width: 32px;
      height: 32px;
    }
  }
}

.range-filter__square {
  .count-popup {
    padding: 33px 33px 13px;
  }
}

.range-mobile-values {
  color: $grey-base;
  font-size: $font-size-base;
  bottom: 102%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @include tablet {
    display: none;
  }
}
