.login-wrapper {
  min-height: 100vh;

  @include laptop {
    display: flex;
  }
}

.login-wrapper__logo {
  width: 272px;
}

.login-image {
  background-image: url(./../assets/img/login-bg.jpg);
  background-size: cover;
  background-position: center center;

  display: none;

  @include laptop {
    display: block;
    flex-basis: 50%;
  }

  @include paddings;
}

.login-logo {
  display: block;
  height: 44px;
  margin-top: 20px;
}

.login-main {
  padding: 25px 30px;

  @include tablet {
    padding: 25px 35px;
  }

  @include laptop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 50%;
    padding: 25px 35px 40px;
  }

  @include short-laptop {
    padding: 25px 35px 20px;
  }
}

.login-main-header {
  display: flex;
  justify-content: space-between;
}

.logo-login {
  img {
    height: 30px;

    @include laptop {
      display: none;
    }
  }
}

.close-login {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  img {
    transition: transform $t;

    @include short-laptop {
      height: 30px;
    }
  }

  @include hover {
    img {
      transform: rotate(0.25turn);
    }
  }
}

.close-login__mobile {
  @include laptop {
    display: none;
  }
}

.close-login__laptop {
  display: none;

  @include laptop {
    display: block;
  }
}

.login-title {
  font-size: $font-size-h4;
  font-weight: $font-bold;
  line-height: 1.25;
  color: $grey-dark;
  text-align: center;

  margin: 50px 0;
  padding: 0;

  @include tablet {
    font-size: $font-size-h2 * 0.85;
  }

  @include laptop {
    margin-bottom: 15px;
    font-size: $font-size-h2;
    white-space: nowrap;
  }

  @include short-laptop {
    font-size: $font-size-h2 * 0.8;
  }
}

.login-title-break {
  @include laptop {
    white-space: unset;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
}

.login-error-wrapper {
  border-radius: 4px;
  margin: 15px 0;
  background: rgba(248, 62, 62, 0.15);

  visibility: hidden;
  opacity: 0;

  @include short-laptop {
    margin: 0 7px;
  }

  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.login-error {
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 15px;
  font-size: $font-size-base;
  line-height: 1.25;
  letter-spacing: -0.1px;
  margin-bottom: 15px;

  @include short-laptop {
    padding: 10px 5px 10px 10px;
  }

  p {
    margin: 0 0 0 25px;
    color: $warning-color;
    font-size: $font-size-small;

    @include short-laptop {
      font-size: 13px;
    }
  }
}

.login-form {
  @include tablet {
    width: 70%;
    min-width: 375px;
    margin: 0 auto 30px;
  }

  @include laptop {
    width: 375px;
    margin: 0 auto 30px;
  }

  @include short-laptop {
    margin: 0 auto;
  }

  & > .login-form {
    width: 100%;
  }

  .custom_button {
    display: block;
    width: 100%;
  }
}

.login-form-wide {
  @include laptop {
    width: auto;
    padding-left: 70px;
    padding-right: 70px;
  }

  .login-form-text {
    margin-top: 50px;
  }
}

.login-form-buttons {
  margin-top: 30px;

  button {
    margin-bottom: 15px;

    @include laptop {
      padding: 15px;
    }
  }
}

.login-form-link {
  color: $primary-color;
  font-weight: $font-bold;
}

.sign-in-with {
  position: relative;
  text-align: center;
  margin: 25px 0;

  @include tablet {
    margin: 35px 0;
  }

  @include short-laptop {
    margin: 15px 0;
  }

  p {
    position: relative;
    z-index: 1;
    margin: 0;
    font-size: $font-size-base;
    line-height: 2;

    @include tablet {
      font-size: $font-size-medium;
    }

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: $grey-base;
      position: absolute;
      top: 50%;
      left: 0;
    }

    span {
      position: relative;
      z-index: 1;
      padding: 0 20px;
      background-color: $global-color;
    }
  }
}

.login-main-footer {
  padding-top: 40px;
  padding-bottom: 30px;

  @include tablet {
    padding-top: 70px;
    padding-bottom: 0;
  }

  p {
    font-size: $font-size-base;
    margin: 0;
    text-align: center;

    @include tablet {
      font-size: $font-size-medium;
    }

    @include laptop {
      text-align: left;
    }

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

.login-info-row {
  padding-top: 5px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-base;

  @include tablet {
    font-size: $font-size-h6;
  }

  @include short-laptop {
    margin-bottom: 20px;
  }

  &.checkbox {
    label {
      span {
        @include mobile-max {
          white-space: nowrap;
        }
      }
    }
  }

  a {
    color: $primary-color;
    @include underlined;
  }
}

.login-form-text {
  text-align: center;
  font-size: $font-size-base;
  line-height: 1.5;
  margin: 0;

  margin: 0 0 30px;

  @include tablet {
    margin: 0 0 40px;
    font-size: $font-size-h5;
  }

  @include short-laptop {
    margin: 0 0 20px;
  }

  @include laptop {
    max-width: 750px;
  }
}

.forgot-password {
  @include mobile-max {
    text-align: right;
  }
}
