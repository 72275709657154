.rc-steps {
  margin-bottom: 20px;

  @include tablet {
    margin-bottom: 40px;
  }

  @include laptop {
    padding: 0 40px;
  }
}

.rc-steps-item-container {
  @include tablet {
    display: flex;
    align-items: center;
  }
}

.rc-steps-item {
  @include mobile-max {
    width: 33%;
    position: relative;
    overflow: visible;

    &:not(:last-child) {
      &:after {
        content: "";
        display: block;
        width: 40px;
        height: 1px;
        background-color: $grey-base;
        z-index: 1;
        position: absolute;
        top: 18px;
        right: 0;
        z-index: 1;
      }
    }

    .rc-steps-item-content {
      width: 70px;
    }

    &:nth-child(2) {
      text-align: center;

      &:after {
        right: -50px;
      }

      .rc-steps-item-icon {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:last-child {
      text-align: right;
      margin-right: 0 !important;

      .rc-steps-item-icon {
        margin-left: auto;
        margin-right: 0 !important;
      }
    }
  }
}

.rc-steps-item {
  @include tablet {
    margin-right: 40px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.rc-steps-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;

  background: linear-gradient(0deg, #b7c0c4, #b7c0c4), #2c8dac;

  border: 1px solid $global-color !important;
  border-radius: 50%;

  @include mobile-max {
    margin-bottom: 10px;
  }

  @include tablet {
    width: 55px;
    height: 55px;
    margin-right: 20px;
  }
}

.rc-steps-icon {
  font-size: $font-size-small;
  font-weight: $font-semibold;
  color: $global-color !important;

  @include tablet {
    font-size: $font-size-h5;
  }
}

.rc-steps-item-title {
  padding-right: 0;
  font-size: $font-size-xs;
  font-weight: $font-semibold;
  line-height: 1.25;
  color: $grey-base;

  @include mobile-max {
    &::after {
      display: none;
    }
  }

  @include tablet-only {
    width: 65px;

    &:after {
      top: 15px;
    }
  }

  @include laptop {
    padding-right: 20px;
  }
}

.rc-steps-item-finish,
.rc-steps-item-active {
  @include mobile-max {
    &::after {
      background-color: $light-primary !important;
    }
  }

  .rc-steps-item-icon {
    background-color: $light-primary;
    position: relative;
    border: 1px solid $light-primary !important;

    &:after {
      content: "";
      position: absolute;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border: 4px solid $global-color;
      border-radius: 50%;
    }
  }

  .rc-steps-item-title {
    color: $primary-color;
  }
}

.rc-steps-item-finish {
  .rc-steps-item-icon {
    &:after {
      background-color: $light-primary;
    }
  }

  .rcicon-check {
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-image: url("./../assets/img/icons/icon-checked-twice.svg");
      background-position: 50% 75%;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      z-index: 1;

      @include tablet {
        width: 30px;
        height: 30px;
        background-position: center center;
      }
    }
  }
}

.rc-steps-item-process .rc-steps-item-title:after {
  background-color: $light-primary;
}

.rc-steps-item-icon > .rc-steps-icon {
  top: 0;
}
