.custom-hotspot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  border: 1px solid white;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: 3px solid white;
    border-radius: 50%;
  }

  &__dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
  }

  &__tooltip {
    display: flex;
    justify-content: center;
    position: relative;
    width: 75px;
    height: 75px;
    &::before {
      display: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      top: -40px;
      content: attr(data-tooltip);
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      max-width: 250px;
      border-radius: 4px;
      padding: 4px 9px;
    }
    &:hover {
      &::before {
        display: block;
      }
    }
  }
}

.pnlm-controls-container {
  z-index: 15;
  top: auto;
  left: auto;
  right: 0;
  bottom: 135px;

  @include tablet() {
    bottom: 135px;
  }

  @include laptop() {
    bottom: 179px;
  }

  @include widescreen() {
    bottom: 210px;
  }
}

.tour-link-rooms__scene .pnlm-controls-container {
  bottom: 93px;
}

.custom-hotspot.logo {
  height: 250px;
  width: 250px;
  border: none;
  z-index: 0;

  @include mobile-max {
    height: 75px;
    width: 75px;
  }
}
