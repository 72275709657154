@mixin input-style {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  font-size: $font-size-base;
  border: 1px solid $grey-light;
}

.tour-wrapper {
  padding: 30px 15px;
  background-color: $global-color;

  @include tablet {
    padding: 30px 40px;
  }

  @include laptop {
    padding: 35px 55px 50px 90px;
  }
}

.tour-wrapper__header {
  padding-bottom: 45px;
  font-size: $font-size-h4;
  font-weight: $font-bold;
  color: $grey-dark;
  text-align: center;
}

.tour-wrapper__title {
  margin-bottom: 60px;
  font-size: $font-size-h5;
  font-weight: $font-medium;
  color: $grey-dark;

  display: none;

  @include tablet {
    display: block;
  }
}

.tour-wrapper__footer {
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }

  @include laptop {
    padding-top: 50px;
  }

  .custom_button-nav {
    width: 100%;
  }
}

.create-tour__form {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  .checkbox-row {
    @include mobile-max {
      margin-bottom: 30px;

      label {
        // justify-content: flex-end;
      }
    }
  }

  .react-select__value-container {
    padding-left: 15px;

    @include tablet {
      padding-left: 20px;
    }
  }

  .react-select-container {
    width: 100%;
  }

  .react-select__single-value {
    margin: 0;
  }

  .react-select__control {
    &,
    &:hover {
      border-color: $grey-light;
    }

    &--menu-is-open {
      box-shadow: 0 0 0 1px $light-primary;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    svg {
      fill: $grey-light;
    }
  }

  .react-select__input {
    input {
      height: 28px;
    }
  }
}

.create-tour__first-column {
  @include tablet {
    flex: 0 1 55%;
  }

  @include laptop {
    flex: 0 1 570px;
  }
}

.create-tour__second-column {
  @include tablet {
    flex: 0 1 40%;
  }

  @include laptop {
    flex: 0 1 350px;
  }
}

.counter-field,
.text-field {
  margin-bottom: 30px;

  @include laptop {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  &.short {
    input {
      @include laptop {
        width: 150px;
      }
    }
  }

  &.long {
    input {
      @include laptop {
        width: 200px;
      }
    }
  }

  label {
    min-width: 150px;
    font-size: $font-size-small;
    font-weight: $font-medium;
    color: $grey-dark;

    @include tablet-max {
      display: block;
      margin-bottom: 8px;
    }
  }

  .required {
    padding-left: 4px;
    color: $warning-color;
  }

  input {
    width: 100%;
    height: 40px;
    padding: 10px 15px;

    font-size: $font-size-base;
    border: 1px solid $grey-light;

    box-shadow: none;
    border-radius: 4px;

    outline: none;
    transition: $t;

    @include tablet {
      padding: 10px;
    }

    &:focus {
      border-color: $light-primary;
    }

    &::placeholder {
      color: $grey-light;

      @include laptop {
        letter-spacing: 1px;
      }
    }

    @include tablet {
      padding: 10px 20px;
    }
  }

  .text-field__input {
    position: relative;
    width: 100%;
  }

  .error {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    color: $warning-color;

    @include mobile-max {
      top: calc(100% + 3px);
      font-size: $font-size-xs;
    }
  }
}

.counter-field {
  @include tablet-max {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    label {
      min-width: auto;
      margin-bottom: 0;
    }
  }

  input {
    padding: 5px;
    border: none;
    text-align: center;
    background-color: $global-color;
  }

  .counter {
    width: 116px;
    margin-left: auto;

    @include tablet {
      width: 100px;
    }

    @include laptop {
      width: 150px;
      margin-left: 0;
    }
  }
}

.flex-row {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  .text-field {
    &:last-child {
      label {
        min-width: 50px;
      }
    }

    @include tablet-only {
      flex-basis: 45%;
    }
  }

  .text-field__input {
    width: auto;
  }
}
