.settings-mobile__nav {
  padding: 20px 20px 0;
  border-radius: 2px;
  background-color: $global-color;

  & > .settings-mobile__title:first-child {
    padding-top: 10px;
  }
}

.settings-mobile__title {
  font-weight: $font-medium;
  font-size: $font-size-h6;
  color: $grey-dark;
  text-transform: uppercase;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 10px;
}

.settings-mobile__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $global-color;

  font-family: $font-family-base;
  font-size: $font-size-small;
  color: $grey-dark;
  padding: 20px 0;
  border-bottom: 1px solid $grey-light-border;

  &:last-child {
    border-bottom: 0;
  }

  img {
    height: 12px;
  }
}
