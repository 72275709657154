.auth-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: 50px;
  background-color: #b8cbc8;
}

.navbar-logo {
  margin: 35px;
}

.auth-navbar-link-wrapper {
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  height: 50px;
  text-decoration: underline;
  color: white;
}

.auth-navbar-link {
  color: white;
  cursor: pointer;
}
