.modal-is-opened {
  // position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $t;
  z-index: 111;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 111;
  opacity: 0;
  animation: modalOverlayAnimation 0.5s forwards;
}

.modal-window {
  position: relative;
  width: 90vw;
  z-index: 112;
  border-radius: 6px;
  background-color: $global-color;
  transform: translateY(25%);
  opacity: 0;
  animation: modalWindowAnimation 0.5s 0.1s forwards;

  max-height: 90vh;
  overflow: auto;

  @include tablet {
    width: 90vw;
  }

  @include laptop {
    width: 915px;
  }
}

$mobile-padding: 90px;

.modal-window-link-room {
  align-items: flex-start;
  padding-top: $mobile-padding;

  .modal-overlay {
    background-color: transparent;
  }

  .simplebar-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .modal-window {
    height: calc(100vh - 150px);
    width: 310px;
    border-radius: 2px;
  }

  .modal-title {
    padding-top: 25px;
    padding-bottom: 30px;
  }

  .modal-content {
    padding: 0 30px;
  }

  .thumbs-vertical {
    background-color: $global-color;

    .thumb {
      width: 100%;
      height: 140px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 30px;

      &.is-active {
        &:after {
          border-width: 4px;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.5;
      }
    }
  }
}

.modal-content {
  padding: 0 40px 30px;

  @include laptop {
    padding: 0 110px 40px;
  }
}

.modal-window-xs {
  @include tablet {
    width: 315px;
  }

  .modal-header__ghost {
    height: 40px;
  }

  .modal-close {
    padding: 8px 15px;
  }

  .modal-title {
    padding-top: 15px;
    font-size: $font-size-base;
  }

  .modal-content {
    @include tablet {
      padding: 0 10px 15px;
    }
  }

  .modal-default {
    padding-top: 5px;

    p {
      font-size: $font-size-xs;
    }

    img {
      width: 38px;
      height: 38px;
      margin-bottom: 0;
    }

    button {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

.modal-window-sm {
  @include laptop {
    width: 650px;
  }

  .modal-content {
    @include laptop {
      padding: 0 40px 30px;
    }
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  padding-top: 35px;
  font-size: $font-size-h6;
  color: $grey-dark;
  text-align: center;

  @include tablet {
    padding-top: 35px;
    font-size: 22px;
    font-weight: $font-medium;
  }
}

.modal-header__ghost {
  width: 48px;
  height: 48px;
}

.modal-close {
  appearance: none;
  margin: 0;
  padding: 15px;
  box-shadow: none;
  background-color: $global-color;
  cursor: pointer;

  img {
    display: block;
    width: 18px;
    height: 18px;
    object-fit: contain;
    transition: transform $t;
  }

  @include hover {
    img {
      transform: rotate(0.25turn);
    }
  }
}

@keyframes modalOverlayAnimation {
  to {
    opacity: 1;
  }
}

@keyframes modalWindowAnimation {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-subscription {
  padding: 30px 20px 20px;

  .modal-subscription__row {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }
  }

  .subscription-item {
    flex-direction: column;

    .main-section {
      max-width: 100%;
    }
  }

  .enterprise-banner {
    display: block;
    width: 100%;
    padding: 15px 25px;

    @include tablet {
      margin: 35px 0 0;
      background-color: rgba(183, 192, 196, 0.15);
      border: none;
    }

    @include tablet {
      &:before {
        content: "";
        position: absolute;
        top: 15px;
        right: 15px;
        width: 100px;
        height: 100px;
        background-image: url(./../assets/img/icons/icon-discount.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &:after {
      top: 20px;
      left: -20px;

      @include tablet {
        display: none;
      }
    }

    .custom_button {
      font-weight: normal;
      border-radius: 0;

      @include tablet {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 10px;
        max-width: 175px;
        min-width: 175px;
      }
    }
  }

  .enterprise-banner__title {
    font-size: $font-size-h4;

    @include tablet {
      font-size: $font-size-base;
      padding-bottom: 10px;
    }
  }

  .enterprise-banner__text {
    @include tablet {
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-size: $font-size-xs;
    }
  }
}

.subscription-item__alert {
  color: $primary-color;
  font-size: $font-size-base;
  text-align: center;
  padding: 0 8px 0 8px;
}

.modal-window-subscription {
  .subscription-item__alert {
    font-size: $font-size-xs;
    width: 50%;
    margin: auto;
  }
}

.modal-purchase-plan {
  padding: 24px 0 0 0;
  @include mobile-max {
    padding: 24px 0 0 0;
  }

  .modal-purchase-plan__header {
    display: flex;
    margin: auto;
    flex-direction: column;
    background-color: $primary-color;
    max-width: 480px;

    b {
      text-transform: capitalize;
      font-weight: 600;
    }

    .billed-annually-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
      margin-bottom: 48px;
      width: "100%";

      background-color: rgba(52, 164, 200, 0.14);
      border: 1px solid #34a4c8;

      border-radius: 4px;
    }
    .billed-annually-values {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .billed-annually-text {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
    }

    p {
      text-align: center;
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 21px;
      color: $global-color;
    }
    .is-old-price {
      margin-left: 16px;
      color: rgba(255, 255, 255, 0.4);
    }
    .is-new-price {
      margin-left: 16px;
      color: $global-color;
    }
    .is-sale {
      color: #34a4c8;
    }
  }

  .modal-purchase-plan__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $global-color;
    width: 100%;

    .modal-purchase-plan__body__title {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      color: #171717;
    }
    .modal-purchase-plan__body__subtitle {
      text-align: center;
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 34px;
      line-height: 100%;
      color: #171717;
    }
    .modal-purchase-plan__body__payment__policy {
      text-align: center;
      font-size: 12px;
      line-height: 10px;
      color: #73868f;
      text-decoration-line: underline;
      margin-top: 18px;
      margin-bottom: 58px;
    }
    .modal-purchase-plan__body__notification {
      text-align: center;
      color: #686868;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 36px;
      margin-bottom: 18px;
      letter-spacing: -0.02em;
    }

    .modal-purchase-plan__body__content {
      margin: auto;
      max-width: 375px;
    }
  }
}

.modal-payment-method {
  padding: 45px 45px 45px 45px;
  @include mobile-max {
    padding: 24px 0;
  }
  .payment-error-wrapper {
    border-radius: 4px;
    background: rgba(248, 62, 62, 0.15);
    display: none;

    &.is-active {
      display: block;
    }

    .payment-error {
      display: flex;
      align-items: center;
      font-size: $font-size-base;
      line-height: 1.25;
      letter-spacing: -0.1px;
      margin-bottom: 20px;
      padding: 10px 5px 10px 10px;

      img {
        position: relative;
        margin-left: 10px;
        left: auto;
        top: -2px;
        transform: translateY(0);
      }

      p {
        margin: 0 10px;
        color: $warning-color;
        font-size: $font-size-small;
        @include short-laptop {
          font-size: 13px;
        }
      }
    }
  }

  .payment-success-wrapper {
    border-radius: 4px;
    display: none;
    background-color: $primary-color;
    &.is-active {
      display: block;
    }

    .payment-success {
      display: flex;

      align-items: center;
      font-size: $font-size-base;
      line-height: 1.25;
      letter-spacing: -0.1px;
      margin-bottom: 20px;
      padding: 10px 5px 10px 10px;

      img {
        position: relative;
        margin-left: 10px;
        left: auto;
        top: -2px;
        transform: translateY(0);
      }

      p {
        margin: 0 10px;
        color: $global-color;
        font-size: $font-size-small;
        @include short-laptop {
          font-size: 13px;
        }
      }
    }
  }

  .modal-payment-method__row {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }
  }

  .text-field {
    position: relative;
    margin-bottom: 24px;
  }

  .is-input-error {
    border-color: #f83e3e;
  }

  input {
    padding-left: 44px;
    color: #73868f;
    border: 1.12915px solid #b7c0c4;
  }

  img {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  p {
    margin-top: 0;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 24px;

    text-align: center;
    color: #686868;
    font-weight: normal;
  }

  button {
    width: 100%;
  }
}

.modal-enterprise {
  padding-bottom: 10px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;

    @include tablet {
      padding-top: 30px;
    }

    button {
      width: 100%;

      @include tablet {
        width: 160px;
      }
    }
  }

  .input-wrapper {
    width: 100%;
    margin-bottom: 20px;

    @include tablet {
      width: 300px;
      margin-bottom: 40px;
    }

    @include laptop {
      width: 230px;
    }

    .input-icon {
      left: 10px;
    }

    input {
      padding-left: 40px;
      height: 40px;
      font-size: $font-size-base;
    }
  }
}

.modal-enterprise__image {
  margin-bottom: 50px;
}

.modal-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 36px;

  p {
    margin: 0;
    font-size: $font-size-small;
    line-height: 2;
    text-align: center;

    br {
      @include mobile-max {
        display: none;
      }
    }
  }

  button {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 20px;
    font-size: $font-size-xs;
    font-weight: $font-bold;
    letter-spacing: 0.04em;

    @include tablet {
      width: 160px;
    }
  }
}

.modal-default__icon {
  display: block;
  width: 74px;
  height: 74px;
  object-fit: contain;
  margin-bottom: 20px;
}

.modal-window-share-tour {
  .modal-header,
  .modal-close {
    background-color: $grey-bg;
  }

  .modal-header {
    @include tablet {
      padding-bottom: 10px;
    }

    @include laptop {
      padding-bottom: 20px;
    }
  }

  .settings-tabs-button {
    padding-bottom: 16px;
    color: $light-primary;
    background-color: $grey-bg;

    &::after {
      background-color: $light-primary;
    }
  }

  .modal-content {
    padding: 0 0 30px;

    @include tablet {
      padding: 0 0 50px;
    }
  }

  .modal-body {
    padding: 30px 20px 0;

    @include tablet {
      min-height: 135px;
      padding: 50px 25px 0;
    }

    @include laptop {
      min-height: 235px;
      padding: 50px 55px 0;
    }

    .error,
    .success {
      text-align: center;
      margin: 0;
      padding: 1em 0 0;

      @include tablet {
        padding: 2em 0;
      }
    }
  }

  .center {
    justify-content: center;
  }

  .btn-group {
    text-align: center;
    @include tablet {
      display: flex;
    }

    input {
      -webkit-appearance: none;
      outline: none;
      box-shadow: none;

      display: block;
      width: 100%;
      padding: 10px 21px;
      border: 1px solid $grey-light;
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      @include mobile-max {
        height: 42px;
        margin-bottom: 20px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 1px solid $grey-light;
      }
    }

    button {
      width: 120px;
      min-width: 120px;

      @include tablet {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      @include mobile-max {
        margin: auto;
      }
    }
  }
}

.modal-window-tour-action {
  .modal-default__icon {
    width: 45px;
    height: 45px;
  }

  .custom_button__light {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.background-color-blue {
  background-color: $primary-color;
}

.color-white {
  color: $global-color;
}
