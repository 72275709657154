.tour-details {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 300px;
  color: $global-color;

  backdrop-filter: blur(11px);
  border-radius: 5px;
  border-top-left-radius: 0;
  top: 53px + 15px;
  padding: 15px;
  transition: $t;
  background: $grey-transparent;
  transform: translateX(100%);

  @media screen and (max-width: 500px) {
    width: 250px;
  }

  @include tablet {
    width: 300px;
    top: 53px + 30px;
    padding: 20px;
  }

  @include laptop {
    top: 62px + 30px;
    padding: 25px 30px 25px 20px;
  }

  @include desktop {
    top: 75px + 30px;
  }

  p {
    margin: 0;
  }

  &.is-active {
    transform: none;
    background: $grey-transparent__dark;

    .tour-details__button {
      background: $grey-transparent__dark;

      img {
        transform: none;
      }
    }
  }
}

.tour-details__button {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  background: $grey-transparent__dark;

  img {
    transition: $t;
    transform: rotate(180deg);
  }
}

.tour-details__header {
  display: flex;
  align-items: stretch;
}

.tour-details__image {
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin-right: 15px;
  background-color: rgba(255, 255, 255, 0.26);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 56px;
    min-width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
  }

  .userIcon {
    width: 44px;
    height: 44px;
  }
}

.tour-details__name {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h3 {
    margin: 0;
    font-size: $font-size-base;
    font-weight: $font-medium;
  }

  p {
    margin: 0;
    font-size: $font-size-xs;
    line-height: 1.5;
  }
}

.tour-details__address {
  padding: 15px 0 0;
  font-weight: $font-medium;
  line-height: 1.35;
}

.tour-details__subtitle {
  padding-top: 25px;

  a {
    color: $global-color;
    text-decoration: underline;
    padding: 0;
    margin: 0;
  }
}

.tour-details__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid $global-color;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  img {
    margin-right: 10px;
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    font-weight: $font-medium;
  }
}

.tour-button {
  appearance: none;
  padding: 0;
  margin: 0;
  font-size: 1px;
  line-height: 1;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: $grey-transparent;
  backdrop-filter: blur(8px);

  width: 40px;
  height: 30px;

  @include tablet {
    width: 45px;
    height: 30px;
  }

  @include laptop {
    width: 60px;
    height: 40px;
  }

  &.tour-details__button {
    backdrop-filter: blur(11px);
    border-top-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;

    width: 30px;
    height: 40px;

    @include tablet {
      width: 30px;
      height: 45px;
    }

    @include laptop {
      height: 60px;
      width: 40px;
    }
  }

  img {
    transition: $t;
  }
}
