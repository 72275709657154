$tablet: 766px;
$laptop: 1200px;
$desktop: 1600px;
$widescreen: 2000px;

$header-padding-mobile: 5px;
$header-padding-tablet: 7px;
$header-padding-laptop: 7px;
$header-padding-desktop: 10px;
$header-padding-widescreen: 15px;

$header-img-height-mobile: 20px;
$header-img-height-tablet: 30px;
$header-img-height-laptop: 35px;
$header-img-height-desktop: 52px;
$header-img-height-widescreen: 70px;

$thumb-height-mobile: 80px;
$thumb-height-tablet: 85px;
$thumb-height-laptop: 119px;
$thumb-height-widescreen: 150px;

$thumb-width-mobile: 100px;
$thumb-width-tablet: 120px;
$thumb-width-laptop: 195px;
$thumb-width-widescreen: 250px;

$thumb-pad-mobile: 10px;
$thumb-pad-tablet: 10px;
$thumb-pad-laptop: 10px;
$thumb-pad-desktop: 23px;
$thumb-pad-widescreen: 30px;

$global-color: #fff;
$warning-color: #df3838;
$success-color: yellowgreen;
$primary-color: #004f6b;
$light-primary: #2c8dac;

$grey-dark: #171717;
$grey-base: #686868;
$grey-light-bg: #f8fafb;
$grey-bg: #f5f6f6;
$grey-table-bg: #f9f9f9;
$grey-light: #b7c0c4;
$grey-light-border: #e6e6e6;

$tour-link-dark: #1e1e1e;

$grey-transparent: rgba(0, 0, 0, 0.3);
$grey-transparent__dark: rgba(0, 0, 0, 0.5);

$pricing-dirty-green: #62899c;
$pricing-green: #1a4458;

$footer-bg: #064359;

$font-light: 300;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

$font-family-base: "Metropolis", sans-serif;

$font-size-h1: 52px;
$font-size-h2: 46px;
$font-size-h3: 32px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$font-size-h6: 18px;

$font-size-medium: 20px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-xs: 12px;
$font-size-xxs: 8px;

$t: 0.3s ease-in-out;

$account-gap: 20px;
$tab-settings-tablet-gap: 25px;
$tab-settings-laptop-gap: 55px;

$upload-image-height-mobile: 28vh;
$upload-image-height-tablet: 160px;

body {
  color: $grey-base;
}
