.watermark-wrapper {
  position: absolute;
  top: 53px + 15px;
  left: 15px;
  z-index: 100;
  pointer-events: none;
  width: 100%;

  @include tablet {
    top: 53px + 30px;
    left: 25px;
  }

  @include laptop {
    top: 62px + 30px;
  }

  @include desktop {
    top: 75px + 30px;
  }

  p {
    display: block;
    margin: 0 0 8px;
    font-size: $font-size-small;
    color: $global-color;
    font-style: italic;
  }

  div {
    display: block;
    width: 100%;
    height: 60px;
    max-width: 150px;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left top;

    @include tablet {
      height: 90px;
      max-width: 200px;
    }

    @include laptop {
      height: 90px;
      max-width: 250px;
    }
  }
}
