.community-tours__page {
  @include laptop {
    padding-bottom: 120px;
  }

  .tour-item__disabled {
    .tour-image {
      background-color: transparent;
      background-image: url(./../assets/img/community-tour-placeholder.jpg);
      background-size: cover;
      filter: blur(3px);
    }
  }
}
