.virtual-staging {
    .tour-item.blur {
        filter: blur(2px);
    }
    .title {
        font-size: $font-size-h3;
        @include tablet-max {
            font-size:  $font-size-h4;
            margin-top: 15px;
        }
    }
    .upload-container{
        display: flex;
        justify-content: center;

        .upload-input-wrapper {
            margin-top: 24px;
            @include upload-image-height;
            display: flex;
            flex-direction: column;
            border: 1px dashed $light-primary;
            background-color: $global-color;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            gap: 10px;
            max-width: 500px;
            width: 100%;
        }
    }

    

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 24px;
        .image {
            object-fit: contain;
            max-height: 400px;
            height: 100%;
            width: 100%;
        }
    }

    .image-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        flex-direction: column;
        gap: 30px;
    }

    .form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .selects-container {
            margin-top: 20px;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        .submit-wrapper {
            margin-top: 30px;
            display: flex;
            justify-content: center;
        }

        .selects-container ~ .submit-wrapper {
            margin-top: 0;
        }

        .checkbox-row {
            display: flex;
            gap: 20px;
            flex-direction: row;

            @include tablet-max {
                flex-direction: column;
                gap: 0;
            }

            &--vertical {
                flex-direction: column;
            }
        }
    }

    .variants-wrapper {
        .variants-left {
          padding-bottom: 200px;
          @include tablet-max {
            padding-bottom: 0;
        }
        }
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        overflow: auto;

        @include tablet-max {
            flex-direction: column;
        }

        .original-image {
            width: 320px;
            height: 220px;
            background-color: black;
            border-radius: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 30px;
        }

        .original-image-title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    .slider-wrapper {
        display: flex; 
        flex-direction: column;
        max-height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        overflow: auto;
        flex: 1;
        @include tablet-max {
            min-height: 500px;
        }

        .slider-selected-image {
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 10px 0;
            flex: 1;
            position: relative;
        }
    }

    .spinner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;    
    }
}

.slider-image-container {
    display: flex;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    background-position: center;
    background-color: white;

    &.rendering-image {
        position: relative;
        cursor: default;
        & .sk-spinner{
            position: absolute;
        }
    }
}