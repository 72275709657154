.subscription-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.subscription-switch__label {
  margin: 0;
  padding: 0 10px;
  font-size: $font-size-xs;
  color: $grey-light;
  transition: color $t;

  @include tablet {
    padding: 0 20px;
  }
}

.monthly {
  .subscription-switch__label {
    &:first-child {
      color: $primary-color;
    }
  }
}

.anually {
  .subscription-switch__label {
    &:last-child {
      color: $primary-color;
    }
  }
}
