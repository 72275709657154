.invoices-wrapper {
  flex-direction: column;
  overflow: hidden;
}

.invoices-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
  font-size: $font-size-xs;
  line-height: 1;

  @include tablet {
    font-size: $font-size-small;
    line-height: 1.5;
  }

  @include mobile-max {
    // display: block;
    width: 100%;
    overflow-x: auto;
  }

  thead {
    border-bottom: 1px solid $grey-light;
  }

  tbody {
    color: $grey-dark;

    tr {
      &:nth-of-type(even) {
        background-color: $grey-table-bg;
      }
    }
  }

  th {
    text-transform: capitalize;
    padding: 12px 3px;
    font-weight: normal;

    @include tablet {
      padding: 17px 0;
    }
  }
}

.invoices-table {
  max-height: 0;
  opacity: 0;
}

.invoices-empty-table {
  font-size: $font-size-base;
  text-align: center;
  padding: 30px;
}

.invoices-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  .invoices-icon {
    transform: rotate(-0.25turn);
    transition: transform 0.15s;
  }

  .settings-form__title {
    @include mobile-max {
      padding: 0;
    }
  }
}

.invoices-wrapper.is-opened {
  .invoices-wrapper {
    border-bottom: 0;
    overflow: auto;
  }

  .invoices-button {
    padding-bottom: 20px;

    @include tablet {
      padding-bottom: 50px;
    }
  }

  .invoices-icon {
    transform: none;
  }

  .invoices-table {
    max-height: 1000px;
    opacity: 1;
    transition: $t;
  }

  .invoices-empty-table {
    max-height: 1000px;
    opacity: 1;
    transition: $t;
  }
}
