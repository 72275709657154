@mixin thumb-size($width, $height) {
  width: $width;
  min-width: $width;
  height: $height;
}

// iPhone 5, 5s, SE
@mixin iphone-sm {
  @media screen and (device-aspect-ratio: 40/71) {
    @content;
  }
}

// iPhone 6, 6s, 7, 8
@mixin iphone-md {
  @media screen and (device-aspect-ratio: 375/667) {
    @content;
  }
}

// iPhone 6+, 7+, 8+
@mixin iphone-lg {
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

// iPhone X
@mixin iphone-x {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

// iPad
@mixin ipad {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

// iPadlandscape
@mixin ipad-landscape {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile-max {
  @media screen and (max-width: #{$tablet - 1}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$tablet}) {
    @content;
  }
}

@mixin tablet-max {
  @media screen and (max-width: #{$laptop - 1}) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: #{$tablet}) and (max-width: #{$laptop - 1}) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: #{$laptop}) {
    @content;
  }
}

@mixin short-laptop {
  @media screen and (min-width: #{$laptop}) and (max-width: #{$desktop}) and (max-height: 1000px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop}) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: #{$widescreen}) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin underlined {
  position: relative;

  &:after {
    content: "";
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width $t;
    position: absolute;
    bottom: -3px;
    left: 0;
  }

  @include hover {
    &:after {
      width: 100%;
    }
  }
}

@mixin paddings {
  @include tablet() {
    padding-left: 35px;
    padding-right: 35px;
  }

  @include desktop {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

@mixin placeholder() {
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin button-reset-styles {
  appearance: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

@mixin fixed-body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  right: 0;
  top: auto;
  bottom: auto;
}

@mixin upload-image-height {
  height: $upload-image-height-mobile;

  @include tablet() {
    height: $upload-image-height-tablet;
  }
}
