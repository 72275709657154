.search-filter-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: $global-color;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  animation: slide 0.5s ease-in-out forwards;

  .range-filter {
    padding-bottom: 20px;

    .title {
      display: none;
    }

    .placeholder:not(.placeholder-range) {
      display: none;
    }
  }

  .count-popup {
    position: static;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0;
  }

  .range-popup {
    padding: 37px 17px 17px;
  }

  .clear-button {
    display: none;
  }
}

.search-filter-mobile__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 30px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 2px rgba(23, 23, 23, 0.1);

  span {
    font-size: 22px;
    font-weight: $font-medium;
    color: $grey-dark;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    margin: 0;
    background-color: $global-color;
  }
}

.search-filter-mobile__title {
  padding-bottom: 16px;
  font-size: $font-size-small;
  font-weight: $font-bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.search-filter-mobile__body {
  padding: 0 30px;
}

.search-filter-mobile__footer {
  padding: 0 30px 30px;

  button {
    display: block;
    width: 100%;

    &:first-child {
      margin-bottom: 20px;
    }
  }
}

@keyframes slide {
  0% {
    opacity: 0.5;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
