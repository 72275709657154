.upload-company-logo {
  margin-bottom: 20px;
  padding: 20px;
  background-color: $global-color;
  border-radius: 2px;
  text-align: center;

  @include laptop {
    margin-bottom: 20px;
    padding: 35px 40px 55px;
  }
}

.ucl__title {
  margin-bottom: 30px;
  font-size: $font-size-medium;
  font-weight: $font-medium;
  color: $grey-dark;

  @include tablet {
    font-size: $font-size-base;
    margin-bottom: 15px;
  }

  @include laptop {
    font-size: $font-size-medium;
    margin-bottom: 30px;
  }
}

.ucl__subtitle {
  margin-bottom: 15px;
  font-size: $font-size-small;
  line-height: 1.5;
  color: $grey-base;
}

.dropzone {
  min-height: 220px;
  padding: 15px 15px 25px;
  margin-bottom: 30px;
  border: 1px dashed $light-primary;
  // cursor: pointer;

  &_hidden {
    overflow: hidden;
    height: 0;
  }
}

.dropzone__title {
  font-size: $font-size-base;
  font-weight: $font-semibold;
  line-height: 165%;
  padding-bottom: 10px;
}

.dropzone__icon {
  padding-bottom: 10px;
}

.dropzone__subtitle {
  font-size: $font-size-xs;
}

.dropzone_thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
}

.dropzone_thumb__inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.dropzone_thumb__img {
  display: block;
  max-width: 100%;
  height: auto;
  min-height: 120px;
  object-fit: contain;
  margin-bottom: 30px;

  @include tablet-max {
    width: 100%;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    object-fit: contain;
  }

  @include laptop {
    min-height: auto;
  }
}
