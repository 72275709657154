.container-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.title-404,
.title-tour-not-found {
  margin: 0;
  color: $grey-dark;
  line-height: 1;
  font-size: $font-size-h1;
  font-weight: $font-bold;

  @include tablet {
    font-size: 15vw;
  }

  @include laptop {
    font-size: 20vw;
  }

  span {
    color: $primary-color;
  }
}

.title-tour-not-found {
  width: 50%;
  padding-bottom: 10vw;
  text-align: center;

  @include tablet {
    padding-bottom: 5vw;
    font-size: 8vw;
  }

  @include laptop {
    padding-bottom: 3vw;
    font-size: 4vw;
  }
}

.subtitle-404 {
  text-transform: uppercase;
  font-size: $font-size-base;
  font-weight: $font-medium;
  text-align: center;
  line-height: 1.5;
  width: 80%;

  @include tablet {
    width: 50%;
    font-size: $font-size-medium;
  }

  @include laptop {
    font-size: $font-size-h4;
    width: 50%;
  }
}
