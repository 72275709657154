@import "./variables";
@import "./mixins";

@import "./scaffolding";
@import "./base";
@import "./container";

@import "./fonts";
@import "./header";
@import "./login";
@import "./account";
@import "./navbar";
@import "./authnavbar";
@import "./tourmanager";
@import "./rangefilter";
@import "./landing";
@import "./edit-tour";

@import "./login-buttons";
@import "./input";
@import "./checkbox";
@import "./select";

@import "./footer";

@import "./page-wrapper";

@import "./stepper";
@import "./tour-add-details";
@import "./tour-upload-images";
@import "./uploadCompanyLogo";
@import "./invoices";
@import "./settings-mobile";
@import "./account-settings-wrapper";
@import "./modal";
@import "./switch";
@import "./alert";

@import "./tours-wrapper";
@import "./tour-card";
@import "./loading";
@import "./search-filter-mobile";
@import "./link-rooms";
@import "./preloader";
@import "./toast";
@import "./credit-card";
@import "./404";
@import "./chart";
@import "./watermark";
@import "./tour_details";
@import "./camera-item";
@import "./community-tours__page";

@import "./pannelum";
@import "./hot-spot";

@import "./virtual-staging";
@import "./badge";
// body {
//   overflow-y: hidden;
// }
// #root {
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
// }

html,
body,
#root {
  height: 100%;
}

#root {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.tour-scene-wrapper {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  // @include iphone-sm {
  //   height: calc(100vh - 70px);
  // }

  // @include iphone-md {
  //   height: calc(100vh - 75px);
  // }

  // @include iphone-lg {
  //   height: calc(100vh - 72px);
  // }

  // @include iphone-x {
  //   height: calc(100vh - 113px);
  // }

  // @include ipad {
  //   height: calc(100vh - 50px);
  // }

  // @include ipad-landscape {
  //   height: auto;
  // }

  &.without-header {
    .separeted-scene-container {
      height: 100%;
    }
  }
}

.is-scene-container {
  width: 100%;
  flex: 1;

  .swiper-wrapper {
    position: relative;

    @include mobile-max() {
      transform: none !important;
    }

    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.separeted-scene-container {
  // height: calc(100vh - 53px);

  // @include laptop {
  //   height: calc(100vh - 62px);
  // }

  // @include desktop {
  //   height: calc(100vh - 75px);
  // }
}

.is-thumb-container {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  transition: transform 0.25s ease-in-out;
  will-change: transform;
  z-index: 10;

  &.is-hidden {
    transform: translateY($thumb-pad-mobile * 2 + $thumb-height-mobile);

    @include tablet() {
      transform: translateY($thumb-pad-tablet * 2 + $thumb-height-tablet);
    }

    @include laptop() {
      transform: translateY($thumb-pad-laptop * 2 + $thumb-height-laptop);
    }

    @include desktop() {
      transform: translateY(139px);
    }

    @include widescreen {
      transform: translateY(170px);
    }

    .is-btn-toggle img {
      transform: rotate(180deg);
    }
  }

  .swiper-container {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.thumbnails-header {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;

  @include tablet {
    padding: 0 40px;
  }

  @include laptop {
    padding: 0 65px;
  }

  .tour-button img {
    @include mobile-max {
      width: 50%;
      max-height: 15px;
    }

    &.icon-vr {
      @include mobile-max {
        width: auto;
        max-height: 18px;
      }

      width: 50%;
    }
  }
}

.thumbs,
.thumbs-vertical {
  width: 100%;
  background: $grey-transparent;

  padding: 10px 0 6px 0;
  white-space: nowrap;
  overflow: auto;

  &--no_scroll {
    overflow: initial;
  }

  @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .backdrop-blur {
      background-color: rgba(255, 255, 255, 0.5);
      -webkit-backdrop-filter: blur(2em);
      backdrop-filter: blur(2em);

      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(3.63144px);
    }
  }

  & .thumb {
    position: relative;
    display: inline-block;
    margin: 0 6px;
    transition: border $t;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid transparent;
      transition: border-color 0.15s ease-in-out;
      pointer-events: none;
    }

    @include thumb-size($thumb-width-mobile, $thumb-height-mobile);

    @include tablet() {
      @include thumb-size($thumb-width-tablet, $thumb-height-tablet);
    }

    @include laptop() {
      @include thumb-size($thumb-width-laptop, $thumb-height-laptop);
    }

    @include widescreen() {
      @include thumb-size($thumb-width-widescreen, $thumb-height-widescreen);
    }

    p {
      margin: 0;
      padding: 2px 5px 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 12px;
      line-height: 1.25;
      color: #fff;
      background: linear-gradient(360deg, #1c1c1c 0%, rgba(54, 54, 54, 0.69) 65.89%, rgba(0, 0, 0, 0) 100%);
      text-align: center;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include laptop {
        padding: 3px 5px;
        font-size: $font-size-small;
        line-height: 1.25;
      }

      @include widescreen {
        font-size: $font-size-h4;
      }
    }

    &.is-active,
    &:hover {
      &:after {
        border-color: rgba($light-primary, 0.75);
      }
    }

    @include laptop() {
      cursor: pointer;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }
}

.thumbs-vertical {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  & .thumb {
    display: block;
    margin: 0 auto 16px;

    @include thumb-size(200px, 120px);

    @include tablet {
      @include thumb-size(150px, 90px);
      margin: 0 auto 25px;
    }

    @include laptop {
      @include thumb-size(200px, 120px);
    }

    p {
      font-size: $font-size-base;

      @include tablet {
        font-size: $font-size-small;
      }

      @include laptop {
        font-size: $font-size-base;
      }
    }
  }
}

.a-loader-title {
  display: none !important;
}

.simplebar-scrollbar:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
}
