.input-wrapper {
  position: relative;
  margin-bottom: 25px;

  @include short-laptop {
    margin-bottom: 15px;
  }

  input {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #b7c0c4;
    padding: 10px 10px 10px 60px;
    font-size: $font-size-base;
    color: $grey-dark;
    font-family: $font-family-base;
    outline: none;
    transition: $t;

    @include tablet {
      font-size: $font-size-h6;
      padding: 15px 15px 15px 70px;
    }

    @include short-laptop {
      height: 45px;
      padding: 5px 5px 5px 60px;
    }

    &:focus {
      border-color: $primary-color;
    }

    &.with-error {
      border-color: $warning-color;
    }
  }

  .input-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    pointer-events: none;

    @include short-laptop {
      height: 20px;
    }
  }
}
