.is-header {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35118);
  background-color: $global-color;

  @include tablet {
    padding: 10px;
  }

  @include laptop {
    padding: 12px;
  }

  @include desktop {
    padding: 15px;
  }

  img {
    height: 33px;

    @include laptop {
      height: 38px;
    }

    @include desktop {
      height: 45px;
    }
  }
}

.header-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $global-color;
  box-shadow: 0px 1px 2px rgba($grey-dark, 0.1);

  @include mobile-max {
    height: 60px;
    padding-left: 14px;
  }

  @include paddings;

  @include tablet-only {
    padding-right: 0;
  }

  @include laptop {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.is-active {
    .navigation-wrapper {
      transform: translateX(0);
    }

    .header-main-logo,
    .navToggle {
      opacity: 0;
    }

    .navigation-overlay {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

.header-homepage {
  background-color: transparent;
  box-shadow: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.41);
    backdrop-filter: blur(32.7964px);
    z-index: -1;
    height: 100%;
  }
}

.header-main-logo {
  @include laptop {
    flex-basis: 33%;
  }

  img {
    height: 38px;

    @include tablet-max {
      height: 30px;
    }
  }
}

.header-main-list__wrapper {
  @include laptop {
    flex-basis: 50%;
    justify-content: center;
  }
}

.header-main-list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include laptop {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    font-size: $font-size-base;
  }
}

.header-main-link {
  position: relative;
  display: inline-block;
  color: $grey-base;
  text-decoration: none;
  text-transform: uppercase;
  transition: color $t;

  // @include underlined;

  @include laptop {
    font-weight: $font-medium;
  }

  &:after {
    content: "";

    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 0px;
    height: 3px;
    background-color: $primary-color;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    // transition: width $t;

    @include laptop {
      bottom: -32px;
    }
  }

  @include hover {
    color: $primary-color;
  }

  &.is-active {
    &:after {
      animation: lineExist 0.3s ease-in-out forwards;
    }
  }

  @include tablet-max {
    padding: 13px 0;
    font-size: $font-size-medium;
    color: $grey-dark;
  }

  @include mobile-max {
    padding: 20px 0 8px;
  }

  @include laptop {
    display: block;
    margin: 0 2vw;
    white-space: nowrap;
  }

  &.is-active {
    color: $primary-color;
  }
}

@keyframes lineExist {
  to {
    width: 50px;
  }
}

.header-main-list__account {
  @include tablet-max {
    border-top: 1px solid $grey-base;
    margin-top: 30px;
    padding-top: 20px;

    .custom_button-nav {
      margin-top: 24px;
    }
  }

  @include tablet {
    display: grid;
    grid-auto-flow: column;
    column-gap: 24px;
    // justify-items: center;
  }
}

.navToggle,
.navClose,
.nav-logo,
.navigation-overlay {
  @include laptop {
    display: none;
  }
}

.navToggle,
.navClose {
  appearance: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.navToggle {
  padding: 15px;

  @include tablet {
    padding: 25px 35px 25px 25px;
  }
}

.header-main-logo,
.navToggle {
  transition: opacity $t;
}

.navigation-wrapper {
  @include tablet-max {
    transform: translateX(100%);
    transition: transform $t;
    will-change: transform;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 80vw;
    padding: 20px 10px 25px 25px;
    background: $global-color;

    @include tablet {
      padding: 35px 20px 50px 50px;
    }
  }

  @include laptop {
    display: flex;
    align-items: center;
    flex-basis: 77%;
    justify-content: space-between;
  }

  @include desktop {
  }
}

.nav-logo {
  padding-top: 5vh;
  padding-bottom: 5vh;

  @include iphone-sm {
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
  }

  img {
    display: block;
    width: 200px;
    object-fit: contain;

    @include iphone-sm {
      width: 150px;
    }
  }
}

.navigation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: rgba(204, 204, 204, 0.5);
  opacity: 0;
  transition: $t;
}
