.upload-images__wrapper {
  padding-top: 20px;
  // padding-bottom: 20px;

  @include tablet {
    padding-top: 25px;
  }

  @include laptop {
    padding-top: 60px;
  }
}

.upload-images {
  @include tablet {
    display: grid;
    row-gap: 20px;
    column-gap: 25px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include laptop {
    row-gap: 30px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.tour-upload-input-wrapper {
  @include upload-image-height;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $light-primary;
  background-color: $global-color;
  cursor: pointer;

  @include mobile-max {
    margin-bottom: 30px;
  }
}

@mixin deleteButton($size) {
  width: $size;
  height: $size;
  top: -$size/2;
  right: -$size/2;
  border-radius: $size/2;
}

.tour-photo {
  width: 100%;

  @include mobile-max {
    padding-bottom: 30px;

    // &:first-child {
    //   padding-top: 0;
    // }
  }

  .image-container {
    position: relative;
  }

  .image-grayscale {
    -webkit-filter: grayscale(100%) blur(1px);
    -moz-filter: grayscale(100%) blur(1px);
    -ms-filter: grayscale(100%) blur(1px);
    -o-filter: grayscale(100%) blur(1px);
    filter: grayscale(100%) blur(1px);
    filter: gray; /* IE 6-9 */
    transition: all 0.5s ease;
  }

  .image-progress-container {
    position: absolute;
    z-index: 2;
    border-radius: 64px;
    width: 64px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: rgba(0, 0, 0, 0.85);

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease;
  }

  .image-retry-container {
    position: absolute;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(254, 240, 242, 0.9);

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      height: 26px;
      background: rgba(242, 58, 93, 1);
      border-radius: 2px;

      span {
        padding-right: 7px;
        font-size: $font-size-xs;
        line-height: $font-size-xs;
        color: $global-color;
      }

      .retry-icon {
        width: 11.83px;
        height: 14px;
        margin-right: 8px;
        margin-left: 7px;
        display: block;
        object-fit: contain;
      }
    }
    button:active {
      background: rgba(242, 58, 93, 0.4);
    }

    .warning-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      span {
        font-size: $font-size-small;
        color: $warning-color;
        line-height: $font-size-small;
      }
      .warning-icon {
        margin-right: 14px;
        display: block;
        width: 20.17px;
        height: 17.42px;
        object-fit: contain;
      }
    }
  }

  .image-container__empty {
    @include upload-image-height;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 25%;
      object-fit: contain;
    }
  }

  img {
    display: block;
    width: 100%;
    @include upload-image-height;

    object-position: top;
    object-fit: cover;

    @include tablet {
      height: 160px;
    }
    user-select: none;
  }

  .delete {
    position: absolute;
    z-index: 1;
    @include deleteButton(46px);

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $global-color;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

    cursor: pointer;
    transition: $t;

    @include tablet {
      @include deleteButton(38px);
    }

    &:hover {
      box-shadow: 0px 0px 6px rgba($primary-color, 0.75);
    }

    img {
      display: block;
      width: 12px;
      height: 12px;
    }
  }
}

.upload-image__info,
.upload-image__error {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  color: $warning-color;

  img {
    margin-top: -2px;
    margin-right: 15px;
  }
}

.upload-image__info {
  color: $warning-color;

  @include tablet-max {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.tour-wrapper__buttons {
  @include mobile-max {
    // padding-top: 10px;
    display: flex;
    flex-direction: column-reverse;

    .custom_button-nav {
      margin-bottom: 15px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  @include tablet {
    display: flex;
  }

  .custom_button-nav {
    @include tablet {
      min-width: 150px;
      margin-left: 50px;
    }
  }
}

.tour-image-progress-value {
  color: $global-color;
  font-size: $font-size-base + 1;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;

  @include tablet {
    font-size: $font-size-small;
  }
}

.tour-image-title {
  padding: 10px 0;
  font-size: $font-size-base + 1;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;

  @include tablet {
    font-size: $font-size-small;
  }
}

.tour-image-title__input {
  border-radius: 0;
  margin: 0;
  -webkit-appearance: none;
  box-shadow: none;
  width: 100%;
  padding: 9px 0 10px;
  border-color: transparent;
  border-bottom: 1px solid $grey-base;
  font-family: $font-family-base;
  color: $grey-base;
  outline: none;
}
